import clsx from "clsx";
import {
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from "react";
import RSModal from "@restart/ui/Modal";
import { CSSTransition } from "react-transition-group";

import "./style.scss";

interface FadeProps {
  children: ReactElement;
  [key: string]: any;
}

const Fade: React.FC<FadeProps> = ({ children, ...props }) => (
  <CSSTransition {...props} timeout={200} classNames="fade">
    {children}
  </CSSTransition>
);
const ZoomFade: React.FC<FadeProps> = ({ children, ...props }) => (
  <CSSTransition {...props} timeout={300} classNames="zoom-fade">
    {children}
  </CSSTransition>
);

interface ModalProps {
  id?: string;
  children?: React.ReactNode;
  headerContent?: ReactNode | string;
  show: boolean;
  backdrop?: "static" | boolean;
  size?: "sm" | "md" | "lg" | "xl" | "full";
  className?: string;
  onClose?: () => void;
}

export function Modal(props: ModalProps) {
  const { id, show, backdrop, onClose } = props;

  const [zIndex, setZIndex] = useState<number | undefined>();

  let modalId = id || "";
  if (!modalId) {
    const randomPart = Math.random().toString(36).slice(2, 11);
    modalId = `modal-${randomPart}`;
  }

  useEffect(() => {
    const visibleModals = document.querySelectorAll(".modal.show").length;
    setZIndex(1055 + visibleModals);
  }, [show]);

  const handleBackdropClick = (e: MouseEvent) => {
    if (
      (e.target as HTMLElement).id === modalId &&
      backdrop !== "static" &&
      typeof onClose === "function"
    ) {
      onClose();
    }
  };

  return (
    <RSModal
      show={show}
      id={modalId}
      aria-labelledby={`${modalId}ModalLabel`}
      transition={ZoomFade}
      backdropTransition={Fade}
      renderBackdrop={backdropProps => (
        <div
          {...backdropProps}
          id={`${modalId}-backdrop`}
          className="modal-backdrop fade show"
          style={{ zIndex }}
        ></div>
      )}
      renderDialog={dialogProps => (
        <div
          {...dialogProps}
          className={clsx("modal fade show d-block", props.className)}
          style={{ zIndex }}
        >
          <div
            className={clsx(
              `modal-dialog modal-dialog-centered modal-${props.size || "md"}`
            )}
          >
            <div className="modal-content">
              {!!props.headerContent && (
                <div className="modal-header">{props.headerContent}</div>
              )}
              <div className="modal-body">{props.children}</div>
            </div>
          </div>
        </div>
      )}
      onClick={handleBackdropClick}
      onHide={onClose}
    />
  );
}
