import clsx from "clsx";
import styles from "./style.module.scss";
import { forwardRef, ForwardedRef } from "react";

type IconProps = {
  className?: string;
  name: string;
  // All other props
  [x: string]: any;
};

const Icon = (
  { name, className, ...rest }: IconProps,
  ref: ForwardedRef<HTMLSpanElement>
) => {
  try {
    const {
      default: SvgComponent
    } = require(`!!@svgr/webpack!assets/icons/${name}.svg`);

    return (
      <span ref={ref} className={clsx(styles.icon, className)}>
        <SvgComponent {...rest} />
      </span>
    );
  } catch (error) {
    return null;
  }
};

export default forwardRef(Icon);
