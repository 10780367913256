import clsx from "clsx";

type SpinnerProps = {
  color?:
    | "light"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark";
  className?: string;
  size?: "sm" | number;
};

export default function Spinner({
  color = "light",
  size = "sm",
  className
}: SpinnerProps) {
  const smClass = size === "sm" ? "spinner-border-sm" : undefined;
  const style =
    size !== "sm" ? { width: `${size}rem`, height: `${size}rem` } : undefined;

  return (
    <div
      className={clsx(`spinner-border text-${color}`, smClass, className)}
      style={style}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}
