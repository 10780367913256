import WelcomeBox from "components/Dashboard/WelcomeBox";
import VideosGrid from "components/Dashboard/VideosGrid";

import styles from "./style.module.scss";

export default function Dashboard() {
  return (
    <>
      <WelcomeBox />

      <VideosGrid title="Recent Videos" className={styles.recentItems} />
    </>
  );
}
