import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect
} from "react";

import alertController from "./controller";

import { AlertModal } from "components/General/AlertModal";

import { AlertOptions } from "support/types";

interface AlertContextProps {
  show: boolean;
  onClose: () => void;
  options: AlertOptions;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within a AlertProvider");
  }
  return context;
};

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState<AlertOptions>({});

  const onClose = () => setShow(false);
  const onOpen = (options: AlertOptions) => {
    setOptions(options);
    setShow(true);
  };

  useEffect(() => {
    alertController.setOnOpenCallback(onOpen);
  }, []);

  return (
    <AlertContext.Provider value={{ show, onClose, options }}>
      {children}

      <AlertModal />
    </AlertContext.Provider>
  );
};
