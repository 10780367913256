import clsx from "clsx";
import { Outlet, useMatch } from "react-router-dom";

import Navbar from "../../components/Dashboard/Navbar";
import SideNav from "../../components/Dashboard/SideNav";

import styles from "./style.module.scss";

export default function DashboardLayout() {
  return (
    <div className={styles.dashboardLayout}>
      <SideNav className={styles.sideNav} />

      <main className={styles.main}>
        <Navbar />

        <div
          className={clsx("container", styles.container, {
            [styles.editContainerClass]: !!useMatch("/videos/:id")
          })}
        >
          <Outlet />
        </div>
      </main>
    </div>
  );
}
