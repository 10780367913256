import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SvgIcon from "components/General/SvgIcon";
import SplitDropdown from "components/General/SplitDropdown";
import { ManageSubModal } from "components/Billing/ManageSubModal";
import Spinner from "components/General/Spinner";

import { useAuth } from "contexts/AuthContext";
import { useBilling } from "contexts/BillingContext";
import { DropdownItem } from "support/types";

import styles from "./style.module.scss";
import alertController from "contexts/AlertContext/controller";

export default function BillingOverview() {
  const navigate = useNavigate();

  const { user } = useAuth();
  const {
    isPlansLoaded,
    loadPlans,
    isPlansLoading,
    userPlan,
    userFeatures,
    isUserOnHighestPlan,
    showUpgradeModal,
    showTopUpModal,
    isResuming,
    resumeSubscription
  } = useBilling();

  const [isShowManageSubModal, setIsShowManageSubModal] = useState(false);

  const showManageSubModal = () => {
    setIsShowManageSubModal(true);
  };

  const hideManageSubModal = () => {
    setIsShowManageSubModal(false);
  };

  const handleSelectAction = (action: DropdownItem) => {
    if (action.id === "upgrade") {
      showUpgradeModal();
    }

    if (action.id === "topup") {
      showTopUpModal();
    }

    if (action.id === "manage") {
      showManageSubModal();
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      navigate("/settings/billing");

      alertController.open({
        icon: "success",
        title: "Payment Successful",
        message: "Your payment was sucessful. Thank you!"
      });
    }

    if (query.get("canceled")) {
      navigate("/settings/billing");

      alertController.open({
        icon: "error",
        title: "Payment Unsuccessful",
        message: "Your payment was not sucessful. Please try again."
      });
    }
  }, [navigate]);

  useEffect(() => {
    if (!isPlansLoaded) {
      loadPlans();
    }
  }, [isPlansLoaded, loadPlans]);

  const dropdownItems: DropdownItem[] = [
    {
      id: "upgrade",
      label: !isUserOnHighestPlan ? "Upgrade" : "View Plans"
    },
    {
      id: "topup",
      label: "Top Up Credit"
    }
  ];

  if (user?.subscription) {
    dropdownItems.push({
      id: "manage",
      label: "Manage Current Subscription"
    });
  }

  function formatDate(isoDate?: string): string {
    if (!isoDate) {
      return "-";
    }
    return moment(isoDate).utc().format("D MMMM YYYY");
  }

  return (
    <div className={styles.billingOverview}>
      {user?.subscription &&
        user?.subscription.status === "SCHEDULED_FOR_SUSPENSION" && (
          <div className="alert alert-info">
            As requested, your subscription will be canceled on{" "}
            <strong>{formatDate(user.subscription.endsAt)}</strong>.
          </div>
        )}

      <div className={styles.currentPlan}>
        <h4>Current Plan</h4>
        {isPlansLoading ? (
          <>
            <div className={styles.planLoadingHeader}>
              <div className={styles.planLoadingName}></div>
              <div className={styles.planLoadingButton}></div>
            </div>

            <div className={styles.planLoadingFeatureItems}>
              {Array.from({ length: 7 }, (_, i) => (
                <div key={i + 1} className={styles.planLoadingFeatureItem}>
                  <div className={styles.planLoadingFeatureName}></div>
                  <div className={styles.planLoadingFeatureValue}></div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div className={styles.planName}>
                {userPlan?.title}

                {user?.subscription &&
                  user?.subscription.status === "SCHEDULED_FOR_SUSPENSION" && (
                    <div className={styles.planEnds}>
                      Ends {formatDate(user.subscription.endsAt)}
                    </div>
                  )}
              </div>

              <div className="d-flex">
                {user?.subscription &&
                user?.subscription.status !== "ACTIVE" ? (
                  <button
                    className="btn btn-primary rounded-pill text-white px-4 fw-bold py-1"
                    disabled={isResuming}
                    onClick={resumeSubscription}
                  >
                    {!isResuming ? "Resume Subscription" : <Spinner />}
                  </button>
                ) : (
                  <SplitDropdown
                    items={dropdownItems}
                    active={dropdownItems[0]}
                    onClick={() => handleSelectAction(dropdownItems[0])}
                    onSelectAction={handleSelectAction}
                  />
                )}
              </div>
            </div>

            <h5 className={styles.featureListTitle}>Features</h5>
            <ul className={styles.featureList}>
              <li className={styles.featureItem}>
                <div className={styles.featureIconWrap}>
                  <SvgIcon name="check"></SvgIcon>
                </div>
                <div className={styles.featureLabel}>Credits per month</div>

                <div className={styles.featureValue}>
                  <div>{userPlan?.credits}</div>
                </div>
              </li>
              {userFeatures.map(feature => (
                <li
                  key={feature.id}
                  className={clsx(styles.featureItem, {
                    [styles.disabled]: !feature.enabled
                  })}
                >
                  <div className={styles.featureIconWrap}>
                    <SvgIcon name="check"></SvgIcon>
                  </div>
                  <div className={styles.featureLabel}>{feature.label}</div>

                  <div className={styles.featureValue}>
                    {feature.type === "boolean" ? (
                      <div>{!!feature.value ? "Yes" : "No"}</div>
                    ) : (
                      <div>{feature.value || 0}</div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <ManageSubModal
        show={isShowManageSubModal}
        onClose={hideManageSubModal}
      />
    </div>
  );
}
