import { useCallback } from "react";

import { RootState } from "store";
import { useAppDispatch, useAppSelector } from "hooks";
import { logout } from "store/reducers/authSlice";

import alertController from "contexts/AlertContext/controller";

type RequestConfig = {
  method?: string;
  body?: BodyInit;
  headers?: Headers;
  credentials?: RequestCredentials;
  signal?: AbortSignal;
};

function useBackend() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const apiRequest = useCallback(
    async (method: string, endpoint: string, options: RequestConfig = {}) => {
      const requestMethod = method.toUpperCase() || "GET";

      let config: RequestConfig = {
        method: requestMethod,
        headers: new Headers({
          "Content-Type": "application/json; charset=UTF-8"
        }),
        credentials: "include", // Must be included to allow server to set cookie in the browser
        ...options
      };

      const validEndpoint = endpoint.replace(/^\//g, "");
      const requestUrl = new URL(
        `${process.env.REACT_APP_API_URL}/${validEndpoint}`
      );

      let url: string;

      if (requestMethod === "GET") {
        const combinedParams = new URLSearchParams({
          ...Object.fromEntries(requestUrl.searchParams)
        });

        const isParamsEmpty = !Array.from(combinedParams.keys()).length;
        const urlParams = !isParamsEmpty ? "?" + combinedParams.toString() : "";

        const { protocol, hostname, port, pathname, hash } = requestUrl;
        const urlPort = port ? `:${port}` : "";

        url = `${protocol}//${hostname}${urlPort}${pathname}${urlParams}${hash}`;
      } else {
        url = requestUrl.toString();
        config = {
          ...config,
          body: JSON.stringify(config.body)
        };
      }

      return fetch(url, config).then(response => {
        if (response.status === 401 && isAuthenticated) {
          dispatch(logout());

          alertController.open({
            message: "Your session has expired. Please login again."
          });
        }

        return response;
      });
    },
    [isAuthenticated, dispatch]
  );

  const get = useCallback(
    async (url: string, options = {}) => {
      return apiRequest("get", url, options);
    },
    [apiRequest]
  );

  const post = useCallback(
    async (url: string, options = {}) => {
      return apiRequest("post", url, options);
    },
    [apiRequest]
  );

  const put = useCallback(
    async (url: string, options = {}) => {
      return apiRequest("put", url, options);
    },
    [apiRequest]
  );

  const del = useCallback(
    async (url: string, options = {}) => {
      return apiRequest("delete", url, options);
    },
    [apiRequest]
  );

  return { get, post, put, del };
}

export default useBackend;
