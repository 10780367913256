import React, { useState } from "react";

import styles from "./style.module.scss";
import clsx from "clsx";

interface ImageWithLoadingProps {
  src?: string;
  alt?: string;
  className?: string;
}

const Image: React.FC<ImageWithLoadingProps> = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className={clsx(styles.Image, className)}>
      {isLoading && <div className={styles.imgLoading}></div>}
      <img src={src} alt={alt} onLoad={handleImageLoad} />
    </div>
  );
};

export default Image;
