import { z } from "zod";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent, useState } from "react";

import styles from "./style.module.scss";

import AuthSubmitButton from "components/Auth/AuthSubmitButton";
import PasswordField from "components/General/PasswordField";
import AuthDescription from "components/Auth/AuthDescription";
import Inputfield from "components/General/InputField";
// import SvgIcon from "components/General/SvgIcon";
import AuthTitle from "components/Auth/AuthTitle";
import Spinner from "components/General/Spinner";

import { ValidationErrors } from "support/types";

import { useAuth } from "contexts/AuthContext";
import useBackend from "hooks/useBackend";

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { post } = useBackend();

  const { fetchAuthenticatedUser } = useAuth();

  // const apiEndpoint = process.env.REACT_APP_API_URL;

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);

    const schema = z.object({
      email: z.string().email({ message: "Please enter a valid email." }),
      password: z.string().min(1, { message: "Password is required" })
    });

    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) {
      return;
    }

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsLoading(true);

      try {
        const res = await post("/auth/login", { body: zResult.data });

        const jsonResonponse = await res.json();

        if (!res.ok) {
          if (jsonResonponse.type === "validation") {
            setErrors(jsonResonponse.errors);
          } else {
            // This will activate the closest `error.js` Error Boundary
            throw new Error("Failed to process data");
          }

          setIsLoading(false);
        } else if (jsonResonponse.status === 200) {
          await fetchAuthenticatedUser();

          navigate("/dashboard");
        }
      } catch (error) {
        setIsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  // const handleGoogleSignin = async () => {
  //   try {
  //     setIsLoading(true);
  //     window.location.href = `${apiEndpoint}/auth/google`;
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error(error);
  //   }
  // };

  // const handleFacebookSignin = async () => {
  //   try {
  //     setIsLoading(true);
  //     window.location.href = `${apiEndpoint}/auth/facebook`;
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error(error);
  //   }
  // };

  return (
    <>
      <AuthTitle>Login to your account</AuthTitle>

      <AuthDescription>
        Don&apos;t have an account yet? <Link to="/signup">Sign up</Link>
      </AuthDescription>

      <form onSubmit={e => handleSubmit(e)} onChange={e => handleFormChange(e)}>
        <Inputfield
          name="email"
          icon="user"
          placeholder="Email address"
          errors={errors.email}
        />

        <PasswordField
          name="password"
          icon="lock-alt"
          placeholder="Password"
          className={styles.passwordField}
          errors={errors.password}
        />

        <Link to="/forgot" className={styles.forgotPassword}>
          Forgot Password
        </Link>

        <AuthSubmitButton>
          {!isLoading ? "Login" : <Spinner />}
        </AuthSubmitButton>
      </form>
      {/* <div className={styles.orLoginWith}>Or Login with</div>
      <div className={styles.socialButtonsWrap}>
        <button className={styles.socialButton} onClick={handleGoogleSignin}>
          <SvgIcon name="google-colored" />
        </button>
        <button className={styles.socialButton}>
          <SvgIcon name="apple" />
        </button>
        <button className={styles.socialButton} onClick={handleFacebookSignin}>
          <SvgIcon name="facebook-colored" />
        </button>
      </div> */}
    </>
  );
}
