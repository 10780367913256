"use client";

import { z } from "zod";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent, useState } from "react";

import styles from "./style.module.scss";

import AuthSubmitButton from "components/Auth/AuthSubmitButton";
import AuthDescription from "components/Auth/AuthDescription";
import PasswordField from "components/General/PasswordField";
import InputField from "components/General/InputField";
import AuthTitle from "components/Auth/AuthTitle";
import Spinner from "components/General/Spinner";

import { useAuth } from "contexts/AuthContext";

import { ValidationErrors } from "support/types";

import useBackend from "hooks/useBackend";

export default function Signup() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { post } = useBackend();

  const { fetchAuthenticatedUser } = useAuth();

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);

    const schema = z
      .object({
        firstName: z
          .string()
          .min(1, { message: "The First Name field is required" }),
        lastName: z
          .string()
          .min(1, { message: "The Last Name field is required" }),
        email: z.string().email({ message: "Please enter a valid email." }),
        password: z.string().min(8),
        passwordConfirmation: z.string()
      })
      .refine(data => data.password === data.passwordConfirmation, {
        message: "Passwords don't match",
        path: ["passwordConfirmation"] // key of error
      });

    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) {
      return;
    }

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsLoading(true);

      try {
        const res = await post("/auth/signup", { body: zResult.data });

        const data = await res.json();

        if (!res.ok) {
          if (data.type === "validation") {
            setErrors(data.errors);
          } else {
            // This will activate the closest `error.js` Error Boundary
            throw new Error("Failed to process data");
          }

          setIsLoading(false);
        } else if (data.status === 200) {
          await fetchAuthenticatedUser();

          navigate("/verify");
        }
      } catch (error) {
        setIsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  return (
    <>
      <AuthTitle>Create an Account</AuthTitle>

      <AuthDescription>
        Already have an account? <Link to="/">Log in</Link>
      </AuthDescription>

      <form onSubmit={e => handleSubmit(e)} onChange={e => handleFormChange(e)}>
        <div className="row gx-3">
          <div className="col">
            <InputField
              icon="user"
              name="firstName"
              placeholder="First Name"
              className="mb-4"
              errors={errors.firstName}
            />
          </div>
          <div className="col">
            <InputField
              icon="user"
              name="lastName"
              placeholder="Last Name"
              className="mb-4"
              errors={errors.lastName}
            />
          </div>
        </div>

        <InputField
          icon="email-at"
          name="email"
          placeholder="Email Address"
          className="mb-4"
          errors={errors.email}
        />

        <div className="row gx-3">
          <div className="col">
            <PasswordField
              icon="lock-alt"
              name="password"
              placeholder="Password"
              errors={errors.password}
            />
          </div>
          <div className="col">
            <PasswordField
              icon="lock-alt"
              name="passwordConfirmation"
              placeholder="Confirm Password"
              errors={errors.passwordConfirmation}
            />
          </div>
        </div>

        <div className={styles.youAgree}>
          By logging in or signing up for Soutle, you agree to Aptiw&apos;s{" "}
          <Link to="https://aptiw.com/terms" target="_blank">
            Terms & Conditions
          </Link>{" "}
          and{" "}
          <Link to="https://aptiw.com/privacy" target="_blank">
            Privacy Policy
          </Link>
          .
        </div>

        <AuthSubmitButton>
          {!isLoading ? "Sign Up" : <Spinner />}
        </AuthSubmitButton>
      </form>
    </>
  );
}
