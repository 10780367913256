import Spinner from "components/General/Spinner";
import SvgIcon from "components/General/SvgIcon";

import "./style.scss";
import clsx from "clsx";
import { MouseEvent } from "react";

interface GenerateInputDropdownProps {
  loading?: boolean;
}

interface Action {
  id: string;
  title: string;
  description: string;
  icon: string;
  disabled?: boolean;
}

const actions: Action[] = [
  {
    id: "magicgen",
    title: "Magic Generate",
    description:
      "Generate a full video complete with subtitles and background music using AI",
    icon: "stars"
  },
  {
    id: "scriptgen",
    title: "Generate Script",
    description:
      "Generate a script and customize it to your taste before generating a video",
    icon: "script",
    disabled: true
  },
  {
    id: "write",
    title: "Write Your Own Script",
    description: "Write your script from scratch using our AI powered editor",
    icon: "pencil-circle",
    disabled: true
  }
];

const GenerateInputDropdown = ({ loading }: GenerateInputDropdownProps) => {
  const handleOnSelectAction = (action: Action, e: MouseEvent) => {
    e.preventDefault();

    if (action.disabled) {
      return e.stopPropagation();
    }
  };

  return (
    <div className="generateButtonsWrap">
      <button type="submit" className="mainBtn">
        {!loading ? (
          <>
            Generate Video <SvgIcon name="stars" className="btnIcon" />
          </>
        ) : (
          <Spinner />
        )}
      </button>
      <div className="dropdown generate-input-dropdown">
        <button
          className="dropdown-toggle chevronBtn"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-offset="0,20"
          aria-expanded="false"
        >
          <SvgIcon name="chevron-down" className="chevronIcon" />
        </button>
        <ul className="dropdown-menu dropdown-menu-end dropdownMenu">
          {actions.map(action => (
            <li
              key={action.id}
              className={clsx("actionItem", {
                active: action.id === "magicgen",
                disabled: action.disabled
              })}
            >
              <a
                className="dropdown-item"
                href="#video"
                onClick={e => handleOnSelectAction(action, e)}
              >
                <div className="actionIconWrap">
                  <SvgIcon name={action.icon} className="actionIcon" />
                </div>
                <div className="actionDetails">
                  <div className="actionTitle">{action.title}</div>
                  <div className="actionDesc">{action.description}</div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GenerateInputDropdown;
