import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Spinner from "components/General/Spinner";
import MinimalNavbar from "components/General/MinimalNavbar";

import toast from "support/toast";

import { useAuth } from "contexts/AuthContext";
import alertController from "contexts/AlertContext/controller";

import { setUser } from "store/reducers/authSlice";
import { AuthUser } from "support/types";

import { useAppDispatch } from "hooks";
import useBackend from "hooks/useBackend";

export default function VerifyEmail() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const RESEND_DELAY = 60; // Delay in seconds
  const [countdown, setCountdown] = useState<number>(RESEND_DELAY);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const { user } = useAuth();

  const { post } = useBackend();

  useEffect(() => {
    if (user?.emailVerifiedAt) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    let lastSentTime = user?.lastVerificationEmailSentAt
      ? new Date(user.lastVerificationEmailSentAt).getTime()
      : new Date().getTime();

    const currentTime = new Date().getTime();
    const elapsedTime = Math.floor((currentTime - lastSentTime) / 1000);
    const remainingTime = RESEND_DELAY - elapsedTime;

    if (remainingTime > 0) {
      setCountdown(remainingTime);
      setIsButtonDisabled(true);

      const timer = setInterval(() => {
        setCountdown((prevCountdown: number) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsButtonDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [user?.lastVerificationEmailSentAt]);

  const handleResendClick = async () => {
    setIsButtonLoading(true);

    const res = await post("/auth/resend");

    const jsonResonponse = await res.json();

    if (res.ok) {
      // Reset the countdown
      setCountdown(RESEND_DELAY);
      setIsButtonDisabled(true);

      dispatch(
        setUser({
          ...user,
          lastVerificationEmailSentAt: new Date().toString()
        } as AuthUser)
      );

      alertController.open({
        icon: "success",
        title: "Verification Link Resent",
        message: `We've sent a new verification link to your email. Please check your inbox (and your spam or promotions folder).`
      });
    } else if (jsonResonponse.status === 422) {
      toast.error(jsonResonponse.message);
    } else {
      console.log("Error:", jsonResonponse);
    }

    setIsButtonLoading(false);
  };

  return (
    <>
      <MinimalNavbar />

      <main>
        <div className="container">
          <div className="col-md-8 offset-md-2">
            <div className="shadow p-5 text-center rounded mt-5">
              <h2 className="mt-5 mb-3 fw-bold">Welcome to Soutle! </h2>
              <p className="mb-3 fw-normal">
                To complete your registration, please verify your email address
                by clicking the link we just sent to your inbox.
              </p>
              <p className="mb-4">
                Didn&apos;t get the email? Check your spam or promotions folder.
              </p>
              <button
                className="btn btn-sm btn-primary text-white px-4 rounded-pill mb-5"
                disabled={isButtonDisabled || isButtonLoading}
                onClick={handleResendClick}
              >
                {isButtonLoading ? (
                  <Spinner />
                ) : isButtonDisabled ? (
                  `Resend in ${countdown}s`
                ) : (
                  "Resend Verification Link"
                )}
              </button>
              <p className="text-muted">
                If you are having trouble verifying your email, please contact
                our support team.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
