import clsx from "clsx";
import { MouseEvent, useState } from "react";

import SvgIcon from "components/General/SvgIcon";
import { ConfirmSubChangeModal } from "../ConfirmSubChangeModal";
import { Modal } from "components/General/Modal";
import Spinner from "components/General/Spinner";

import { useBilling } from "contexts/BillingContext";
import { Feature, Plan } from "support/types";
import { useAuth } from "contexts/AuthContext";
import { getPlanPrice, getPlanPriceRate } from "support/helpers";

import useBackend from "hooks/useBackend";

import toast from "support/toast";

import "./style.scss";

interface UpgradeModalProps {
  show?: boolean;
  onClose?: () => void;
}

export function UpgradeModal({ show = false, onClose }: UpgradeModalProps) {
  const { post } = useBackend();

  const { user } = useAuth();
  const { isPlansLoading, isUserOnHighestPlan, plans, userPlan, features } =
    useBilling();

  const listedPlans = plans.filter(p => p.listed);

  const [recurringInterval, setRecurringInterval] = useState("annually");
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [newPlan, setNewPlan] = useState<Plan | null>(null);
  const [planLoading, setPlanLoading] = useState<string | null>(null);

  const handleOnChooseInterval = (interval: string, e: MouseEvent) => {
    setRecurringInterval(interval);
  };

  const createStripeCheckoutSession = async (plan: Plan) => {
    const planPrice = plan?.prices.find(p => p.interval === recurringInterval);

    setPlanLoading(plan.slug);

    try {
      const res = await post("/billing/create-checkout-session", {
        body: { priceSlug: planPrice?.slug }
      });

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setPlanLoading(null);
      } else {
        window.location.href = jsonResonponse.data.url;

        // Intentionally refuse to stop spinner
      }
    } catch (error) {
      setPlanLoading(null);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const handleChoosePlan = (plan: Plan) => {
    if (user?.subscription) {
      setNewPlan(plan);

      return setIsShowConfirmModal(true);
    }

    createStripeCheckoutSession(plan);
  };

  const isButtonDisabled = (plan: Plan) => {
    if (userPlan && userPlan.slug === plan.slug) {
      const price = getPlanPrice(plan, recurringInterval);

      if (user?.subscription && user?.subscription.priceSlug === price?.slug) {
        return true;
      }
    }

    return false;
  };

  const getButtonText = (plan: Plan) => {
    if (!userPlan) {
      return "Upgrade";
    } else if (userPlan.slug === plan.slug) {
      const price = getPlanPrice(plan, recurringInterval);

      if (user?.subscription && user?.subscription.priceSlug !== price?.slug) {
        const interval = price?.interval === "monthly" ? "monthly" : "annual";
        return `Switch to ${interval} plan`;
      }

      return "This is your active Subscritpion";
    } else if (userPlan.slug !== plan.slug) {
      if (userPlan.displayOrder < plan.displayOrder) {
        return "Upgrade";
      }

      return "Downgrade";
    }
  };

  const getFeatureFromPlan = (plan: Plan, feature: Feature) => {
    return plan.features.find(ft => ft.id === feature.id);
  };

  const handleOnClose = () => {
    setRecurringInterval("annually");

    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <Modal show={show} className="upgrade-plan-modal" onClose={handleOnClose}>
        <h2>
          {isUserOnHighestPlan
            ? "Subscription plans"
            : "Upgrade your subscription"}
        </h2>
        <p>Enjoy an enhanced experience and top-tier video creation tools.</p>

        <button type="button" className="btn-close" onClick={handleOnClose}>
          <SvgIcon name="close-circle" />
        </button>

        <div className="intervalSwitchWrap">
          <div
            className={clsx("interval", {
              active: recurringInterval === "monthly"
            })}
            onClick={e => handleOnChooseInterval("monthly", e)}
          >
            Monthly
          </div>
          <div
            className={clsx("interval", {
              active: recurringInterval === "annually"
            })}
            onClick={e => handleOnChooseInterval("annually", e)}
          >
            Annually
            <div className="best">Best value</div>
          </div>
        </div>

        <div className="plans">
          {isPlansLoading
            ? Array.from({ length: 4 }, (_, index) => (
                <div key={index + 1} className="plan-item plan-loading">
                  <div className="plan-name-loading"></div>
                  <div className="plan-description"></div>
                  <div className="plan-price-section-loading"></div>

                  <div className="plan-billed-loading"></div>

                  {Array.from({ length: 5 }, (_, i) => (
                    <div key={i + 11} className="plan-feature-item"></div>
                  ))}

                  <div className="plan-price-button-loading"></div>
                </div>
              ))
            : listedPlans.map(plan => (
                <div
                  className={clsx("plan-item", {
                    highlight: !user?.subscription && plan.mostPopular
                  })}
                  key={plan.slug}
                >
                  {userPlan?.slug === plan.slug && (
                    <div className="plan-badge">Active</div>
                  )}
                  {!user?.subscription && plan.mostPopular && (
                    <div className="plan-badge">Most Popular</div>
                  )}
                  <div className="plan-name">{plan.title}</div>
                  <div className="plan-description">{plan.description}</div>

                  <div className="plan-price-section">
                    <div className="plan-price-wrap">
                      <div className="plan-currency">$</div>
                      <div className="plan-amount">
                        {getPlanPriceRate(plan, recurringInterval)}
                      </div>
                      <div className="plan-interval">/ MO</div>
                    </div>

                    <div className="plan-billed">
                      Billed {recurringInterval}{" "}
                      {recurringInterval === "annually" && (
                        <div className="save">Save 20%</div>
                      )}
                    </div>
                  </div>

                  <button
                    className="btn choose-btn"
                    disabled={isButtonDisabled(plan)}
                    onClick={() => handleChoosePlan(plan)}
                  >
                    {planLoading !== plan.slug ? (
                      getButtonText(plan)
                    ) : (
                      <Spinner color={plan.mostPopular ? "primary" : "light"} />
                    )}
                  </button>

                  <div className="plan-features">
                    <div className="plan-feature-item">
                      <div className="plan-feature-icon">
                        <SvgIcon name="verified" />
                      </div>
                      <div className="plan-feature-label">
                        {plan.credits} Credits Monthly
                      </div>
                    </div>
                    {features.map(feature => (
                      <div
                        key={feature.id}
                        className={clsx("plan-feature-item", {
                          disabled: !getFeatureFromPlan(plan, feature)
                        })}
                      >
                        <div className="plan-feature-icon">
                          <SvgIcon name="verified" />
                        </div>
                        <div className="plan-feature-label">
                          {feature.label}
                        </div>
                        {getFeatureFromPlan(plan, feature) &&
                          feature.type !== "boolean" && (
                            <div className="ms-2">
                              {getFeatureFromPlan(plan, feature)?.value}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
        </div>
      </Modal>

      <ConfirmSubChangeModal
        show={isShowConfirmModal}
        frequency={recurringInterval}
        newPlan={newPlan}
        onClose={() => setIsShowConfirmModal(false)}
      />
    </>
  );
}
