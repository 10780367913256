import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useEffect,
  useState,
  useCallback
} from "react";

import { useAppSelector } from "hooks";

import { RootState } from "store";
import { setIsBusy, selectUser, login, logout } from "store/reducers/authSlice";
import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router-dom";

import { AuthUser } from "support/types";
import useBackend from "hooks/useBackend";

interface AuthContextProps {
  isAuthLoading: boolean;
  isBusy: boolean;
  isAuthenticated: boolean;
  user: AuthUser | null;
  fetchAuthenticatedUser: (signal?: AbortSignal | undefined) => Promise<void>;
  handleLogout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const isBusy = useAppSelector((state: RootState) => state.auth.isBusy);
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const { get } = useBackend();

  const fetchAuthenticatedUser = useCallback(
    (signal: AbortSignal | undefined = undefined) => {
      setIsAuthLoading(true);

      return get("/auth/me", { signal })
        .then(async res => {
          if (res.ok) {
            try {
              const jsonResonponse = await res.json();

              const fetchedUser = jsonResonponse.data;

              fetchedUser && dispatch(login(fetchedUser));
            } catch (error) {}
          }

          setIsAuthLoading(false);
        })
        .catch(e => {
          // If its AbortError, let's assume the user reloaded the page
          if (e.name !== "AbortError") {
            setIsAuthLoading(false);
          }
        });
    },
    [get, dispatch]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (!isAuthenticated) {
      fetchAuthenticatedUser(signal);
    } else {
      setIsAuthLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [isAuthenticated, fetchAuthenticatedUser]);

  const handleLogout = () => {
    dispatch(setIsBusy(true));

    get("/auth/logout").then(async res => {
      if (res.ok) {
        dispatch(logout());

        navigate("/");

        dispatch(setIsBusy(false));
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthLoading,
        isBusy,
        isAuthenticated,
        user,
        fetchAuthenticatedUser,
        handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
