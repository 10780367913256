import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState
} from "react";
import { useStripe } from "@stripe/react-stripe-js";

import SvgIcon from "components/General/SvgIcon";
import { Modal } from "components/General/Modal";
import Spinner from "components/General/Spinner";
import { SelectPaymentMethod } from "../SelectPaymentMethod";
import { AddPaymentMethodModal } from "../AddPaymentMethodModal";

import { useBilling } from "contexts/BillingContext";
import { useAuth } from "contexts/AuthContext";
import { setUser } from "store/reducers/authSlice";
import { useAppDispatch } from "hooks";
import useBackend from "hooks/useBackend";

import { AuthUser, PaymentMethod } from "support/types";
import toast from "support/toast";
import { formatMoney } from "support/helpers";

import alertController from "contexts/AlertContext/controller";

import "./style.scss";

interface TopUpModalProps {
  show: boolean;
  onClose: () => void;
}

const pricePerCredit = 0.2;

export function TopUpModal({ show, onClose }: TopUpModalProps) {
  const dispatch = useAppDispatch();

  const { post } = useBackend();

  const { user } = useAuth();

  const stripe = useStripe();

  const {
    isPlansLoading,
    userPlan,
    showUpgradeModal,
    isResuming,
    resumeSubscription,

    paymentMethods,
    paymentMethodsLoading,
    paymentMethodsLoaded,
    setPaymentMethods
  } = useBilling();

  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [creditsCount, setCreditsCount] = useState(50);
  const [payMethod, setPayMethod] = useState<PaymentMethod | undefined>();
  const [isShowAddPayMethodModal, setIsShowAddPayMethodModal] = useState(false);

  const [isShow3DsModal, setIsShow3DsModal] = useState(false);
  const [intentSecret, setIntentSecret] = useState<string | null>(null);
  const [threeDSecureUrl, setThreeDSecureUrl] = useState<string | null>(null);

  const handleOnClose = useCallback(() => {
    onClose();

    setCreditsCount(50);
  }, [onClose]);

  const handleOnCreditsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);

    setCreditsCount(value);
  };

  const sendTopUpRequest = async () => {
    setIsContinueLoading(true);

    if (!user) {
      return;
    }

    if (!creditsCount || !payMethod) {
      toast.error("Please fill out all required fields");
    }

    try {
      const res = await post("/billing/top-up", {
        body: { quantity: creditsCount, paymentMethod: payMethod?.id }
      });

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setIsContinueLoading(false);
      } else if (jsonResonponse.status === 202) {
        const data = jsonResonponse.data;

        setIntentSecret(data.clientSecret);
        setThreeDSecureUrl(data.redirect.url);
        setIsShow3DsModal(true);
      } else if (jsonResonponse.status === 200) {
        handleOnTopUpSuccessful();

        setIsContinueLoading(false);
      }
    } catch (error) {
      setIsContinueLoading(false);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const handleOnTopUpSuccessful = useCallback(() => {
    if (user) {
      // Note: The update below is for display purposes only.
      // This endpoint when successful emits a `creditsUpdated` event
      // which is how you know the changes has actually been effected.
      // This event is handled in the BillingContext Provider
      dispatch(
        setUser({
          ...user,
          credits: user.credits + creditsCount
        } as AuthUser)
      );
    }

    handleOnClose();

    alertController.open({
      icon: "success",
      title: "Top up successful",
      message: (
        <>
          Your top up request for <strong>{creditsCount}</strong> credits was
          successful.
        </>
      )
    });
  }, [user, dispatch, creditsCount, handleOnClose]);

  const handle3DSModalOnClose = useCallback(() => {
    setIsContinueLoading(false);

    setIsShow3DsModal(false);
  }, []);

  const handleOnContinue = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!user) {
      return;
    }

    sendTopUpRequest();
  };

  const handlePaymentMethodAdded = (pm: PaymentMethod) => {
    setPaymentMethods([pm, ...paymentMethods]);

    setTimeout(() => {
      setPayMethod(pm);
    });

    setIsShowAddPayMethodModal(false);
  };

  const on3DSComplete = useCallback(() => {
    if (!intentSecret) {
      return;
    }

    // Hide the 3DS UI
    handle3DSModalOnClose();

    // Check the PaymentIntent
    stripe
      ?.retrievePaymentIntent(intentSecret!)
      .then(function (result) {
        if (result.error) {
          // PaymentIntent client secret was invalid
          alertController.open({
            icon: "error",
            title: "Failed to process payment",
            message:
              "An error occurred while processing your payment. Please try again"
          });
        } else {
          if (result.paymentIntent.status === "succeeded") {
            // Payment has succeeded
            handleOnTopUpSuccessful();
          } else if (
            result.paymentIntent.status === "requires_payment_method"
          ) {
            // Authentication failed, prompt the customer to enter another payment method
            alertController.open({
              icon: "error",
              title: "Failed to process payment",
              message:
                "We were unable to process your payment. Please try another payment method."
            });
          }
        }
      })
      .finally(() => {
        setIntentSecret(null);
        setThreeDSecureUrl(null);
      });
  }, [stripe, intentSecret, handle3DSModalOnClose, handleOnTopUpSuccessful]);

  useEffect(() => {
    const handler = (ev: any) => {
      if (ev.data === "3DS-authentication-complete") {
        on3DSComplete();
      }
    };

    window.addEventListener("message", handler, false);

    return () => {
      window.removeEventListener("message", handler, false);
    };
  }, [on3DSComplete]);

  useEffect(() => {
    if (paymentMethodsLoaded) {
      setPayMethod(paymentMethods.find(pm => pm.isDefault));
    }
  }, [paymentMethodsLoaded, paymentMethods]);

  const handleUpgrade = () => {
    showUpgradeModal();
  };

  let amount = "$0.00";

  if (creditsCount) {
    amount = formatMoney(creditsCount * pricePerCredit);
  }

  const shouldShowUpgrade =
    !user?.subscription ||
    (user?.subscription &&
      user?.subscription.status === "ACTIVE" &&
      userPlan?.slug !== "premiumplus");

  const shouldShowResume =
    user?.subscription && user?.subscription.status !== "ACTIVE";

  const headerContent = (
    <>
      <h5 className="modal-title">Credit Top Up</h5>
      <p className="modal-description">
        Your credit balance allows for increased feature usage.
      </p>

      <button type="button" className="btn-close" onClick={handleOnClose}>
        <SvgIcon name="close-circle" />
      </button>
    </>
  );

  return (
    <>
      <Modal
        show={show}
        className="credit-topup-modal"
        headerContent={headerContent}
        onClose={handleOnClose}
      >
        {isPlansLoading ? (
          <div className="text-center p-5">
            <Spinner color="primary" size={5} />
          </div>
        ) : (
          <form method="POST" onSubmit={handleOnContinue}>
            {(shouldShowUpgrade || shouldShowResume) && (
              <div className="current-plan">
                <div className="current-plan-icon">
                  <SvgIcon name="star-sub" />
                </div>
                <div className="current-plan-content">
                  {shouldShowUpgrade && (
                    <>
                      <div className="current-plan-title">
                        You are on {userPlan?.title} plan
                      </div>
                      <div className="current-plan-description">
                        Want more credits every month?{" "}
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          onClick={handleUpgrade}
                        >
                          Upgrade
                        </button>
                        .
                      </div>
                    </>
                  )}

                  {shouldShowResume && (
                    <>
                      <div className="current-plan-title">
                        Your subscription is inactive
                      </div>
                      <div className="current-plan-description">
                        Resume your subscription to receive credits every month.{" "}
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          disabled={isResuming}
                          onClick={resumeSubscription}
                        >
                          {!isResuming ? (
                            "Resume Subscription"
                          ) : (
                            <Spinner color="primary" />
                          )}
                        </button>
                        .
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="mb-4">
              <label htmlFor="topupAmount" className="top-up-label">
                Number of credits to add
              </label>
              <input
                id="topupAmount"
                type="number"
                className="form-control"
                step={5}
                min={25}
                max={2000}
                value={creditsCount}
                onChange={handleOnCreditsChange}
              />
              <div
                id="topupAmountHelp"
                className="form-text d-flex justify-content-between"
              >
                Enter an amount between 25 and 2,000
                <strong>Due: {amount}</strong>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="pm" className="top-up-label">
                Payment method
              </label>
              <SelectPaymentMethod
                id="pm"
                required
                selected={payMethod}
                onSelect={setPayMethod}
              />
              <div id="pmHelp" className="form-text d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-link add-pm-button"
                  onClick={() => setIsShowAddPayMethodModal(true)}
                >
                  <SvgIcon name="plus" className="add-pm-plus-icon" />
                  Add payment method
                </button>
              </div>
            </div>
            <div className="text-end mt-3">
              <button
                type="submit"
                className="btn btn-primary text-white fw-bold px-4"
                disabled={
                  !creditsCount || isContinueLoading || paymentMethodsLoading
                }
              >
                {paymentMethodsLoading || isContinueLoading ? (
                  <Spinner />
                ) : (
                  `Pay ${amount}`
                )}
              </button>
            </div>
          </form>
        )}
      </Modal>

      <Modal show={isShow3DsModal} className="threeDsModal">
        <button
          type="button"
          className="btn-close"
          onClick={handle3DSModalOnClose}
        ></button>

        {threeDSecureUrl && (
          <iframe
            src={threeDSecureUrl}
            title="3Ds"
            width={600}
            height={400}
          ></iframe>
        )}
      </Modal>

      <AddPaymentMethodModal
        show={isShowAddPayMethodModal}
        onClose={() => setIsShowAddPayMethodModal(false)}
        onAdded={handlePaymentMethodAdded}
      />
    </>
  );
}
