import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { useAuth } from "contexts/AuthContext";

export const EmailVerifyProtectedRoute = () => {
  const { user } = useAuth();

  if (!user?.emailVerifiedAt) {
    return <Navigate to="/verify" />;
  }

  return <Outlet />;
};
