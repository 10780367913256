import clsx from "clsx";
import styles from "./style.module.scss";

interface PageLoadingProps {
  color?:
    | "light"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark";
}

export default function PageLoading({ color = "primary" }: PageLoadingProps) {
  return (
    <div className={styles.pageLoading}>
      <div
        className={clsx(`spinner-border text-${color}`, styles.spinnerBorder)}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
