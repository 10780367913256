import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Spinner from "components/General/Spinner";
import MinimalNavbar from "components/General/MinimalNavbar";

import useBackend from "hooks/useBackend";

import { useAuth } from "contexts/AuthContext";
import alertController from "contexts/AlertContext/controller";

import { setUser } from "store/reducers/authSlice";
import { useAppDispatch } from "hooks";
import { AuthUser } from "support/types";

export default function EmailTokenVerify() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { post } = useBackend();

  const [isValidatingToken, setIsValidatingToken] = useState<boolean>(true);

  const [message, setMessage] = useState<string | null>(null);

  const { isAuthLoading, isAuthenticated, user } = useAuth();

  const { token } = useParams();

  useEffect(() => {
    if (isAuthLoading) {
      return;
    }

    if (token) {
      setIsValidatingToken(true);

      post(`/auth/verify/${token}`)
        .then(async response => {
          const data = await response.json();

          if (response.status === 200) {
            alertController.open({
              icon: "success",
              message:
                data.data.message ||
                "Your email has been successfully verified!"
            });

            if (isAuthenticated) {
              dispatch(
                setUser({
                  ...user,
                  emailVerifiedAt: new Date().toString(),
                  credits: data.data.credits || user?.credits
                } as AuthUser)
              );

              navigate("/dashboard");
            } else {
              navigate("/");
            }
          } else if (response.status > 400 && response.status < 499) {
            setMessage(
              data.data.message ||
                "Verification link invalid or has expired. Please request a new one."
            );
          } else {
            setMessage(
              data.data.message ||
                "An unexpected error occurred. Please try again later."
            );
          }
        })
        .catch(() => {
          setMessage("Failed to verify email. Please try again.");
        })
        .finally(() => {
          setIsValidatingToken(false);
        });
    } else {
      setMessage(
        "No verification token found. Please check the link or request a new verification link."
      );
    }
  }, [post, isAuthLoading, user, isAuthenticated, navigate, token, dispatch]);

  const handleGoBackClick = async () => {
    isAuthenticated ? navigate("/dashboard") : navigate("/");
  };

  return (
    <>
      <MinimalNavbar />

      <main>
        <div className="container">
          <div className="col-md-8 offset-md-2">
            <div className="shadow p-5 text-center rounded mt-5">
              {isValidatingToken ? (
                <div className="p-5 m-5">
                  <Spinner size={5} color="primary" />
                </div>
              ) : (
                <>
                  <h2 className="mt-5 mb-3 fw-bold">
                    Invalid verification link
                  </h2>
                  <p className="mb-3 fw-normal">{message}</p>
                  <p className="mb-3 fw-normal">
                    If you are having trouble verifying your email, please
                    contact our support team.
                  </p>
                  <button
                    className="btn btn-sm btn-primary text-white px-4 rounded-pill mb-5"
                    onClick={handleGoBackClick}
                  >
                    Go back
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
