import { AlertOptions } from "support/types";

class AlertController {
  private onOpenCallback: ((options: AlertOptions) => void) | null = null;

  setOnOpenCallback(callback: (options: AlertOptions) => void) {
    this.onOpenCallback = callback;
  }

  open(options: AlertOptions = {}) {
    if (this.onOpenCallback) {
      this.onOpenCallback(options);
    } else {
      console.error("Alert open callback is not set");
    }
  }
}

const alertController = new AlertController();
export default alertController;
