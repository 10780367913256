export type SubmitButtonProps = {
  children?: React.ReactNode;
  className?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  id?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function SubmitButton({
  type = 'submit',
  className,
  children,
  disabled,
}: SubmitButtonProps) {
  return (
    <button type={type} className={className} disabled={disabled}>
      {children}
    </button>
  );
}
