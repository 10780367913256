import styles from "./style.module.scss";

const notificationPrefs = [
  {
    id: "account",
    label: "Account Notifications",
    web: {
      checked: true,
      disabled: true
    },
    email: {
      checked: true,
      disabled: true
    }
  },
  {
    id: "payment",
    label: "Payment Notifications",
    web: {
      checked: true,
      disabled: true
    },
    email: {
      checked: true,
      disabled: true
    }
  },
  {
    id: "videorender",
    label: "Video Rendering",
    web: {
      checked: true,
      disabled: true
    },
    email: {
      checked: false,
      disabled: false
    }
  },
  {
    id: "weeklyreport",
    label: "Weekly Report",
    web: {
      checked: true,
      disabled: false
    },
    email: {
      checked: true,
      disabled: false
    }
  },
  {
    id: "promo",
    label: "Promotions",
    web: {
      checked: true,
      disabled: false
    },
    email: {
      checked: true,
      disabled: false
    }
  }
];

export default function NotificationSettings() {
  return (
    <div className={styles.notificationSettings}>
      <h3>Notifications</h3>
      <p>Configure your notification preferences.</p>

      <div className={styles.notificationSettingsWrap}>
        {notificationPrefs.map(pref => (
          <div key={pref.id} className={styles.settingItem}>
            <div className={styles.settingLabel}>{pref.label}</div>

            <div className={styles.settingOptions}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={`${pref.id}emailCheck`}
                  defaultChecked={pref.email.checked}
                  disabled={pref.email.disabled}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${pref.id}emailCheck`}
                >
                  Email
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={`${pref.id}webCheck`}
                  defaultChecked={pref.web.checked}
                  disabled={pref.web.disabled}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${pref.id}webCheck`}
                >
                  Web
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
