import { toast as ReactToastify, ToastOptions, Bounce } from "react-toastify";

const config: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce
};

const toast = {
  info: (message: string, params: object = {}) =>
    ReactToastify.info(message, { ...config, ...params }),
  error: (message: string, params: object = {}) =>
    ReactToastify.error(message, { ...config, ...params }),
  success: (message: string, params: object = {}) =>
    ReactToastify.success(message, { ...config, ...params }),
  warn: (message: string, params: object = {}) =>
    ReactToastify.warn(message, { ...config, ...params })
};

export default toast;
