import { useEffect } from "react";

import Spinner from "components/General/Spinner";

import styles from "./style.module.scss";

export function On3DSComplete() {
  useEffect(() => {
    window.top?.postMessage("3DS-authentication-complete");
  }, []);

  return (
    <div className={styles.on3DSCompleteWrap}>
      <Spinner color="primary" size={5} />
    </div>
  );
}
