import clsx from "clsx";
import { useEffect, useState } from "react";

import SvgIcon from "components/General/SvgIcon";
import Spinner from "components/General/Spinner";
import { AddPaymentMethodModal } from "components/Billing/AddPaymentMethodModal";

import { useBilling } from "contexts/BillingContext";
import alertController from "contexts/AlertContext/controller";

import { PaymentMethod } from "support/types";
import useBackend from "hooks/useBackend";

import styles from "./style.module.scss";

export default function BillingPaymentMethods() {
  const {
    paymentMethods,
    paymentMethodsLoading,
    paymentMethodsLoaded,
    hasMorePaymentMethods,
    loadPaymentMethods,
    setPaymentMethods
  } = useBilling();

  const { post, del } = useBackend();

  const [isDefaultBusy, setIsDefaultBusy] = useState<string | null>(null);
  const [isDeleteBusy, setIsDeleteBusy] = useState<string | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isShowAddPayMethodModal, setIsShowAddPayMethodModal] = useState(false);

  async function setDefaultPaymentMethod(paymentMethodId: string) {
    setIsDefaultBusy(paymentMethodId);

    const response = await post("/billing/payment-methods/default", {
      body: { paymentMethodId }
    });

    if (response.ok) {
      setPaymentMethods(
        paymentMethods.map(pm => ({
          ...pm,
          isDefault: pm.id === paymentMethodId
        }))
      );

      return setIsDefaultBusy(null);
    }

    setIsDefaultBusy(null);
  }

  async function deletePaymentMethod(paymentMethodId: string) {
    setIsDeleteBusy(paymentMethodId);

    const response = await del(`/billing/payment-methods/${paymentMethodId}`);

    if (response.ok) {
      const index = paymentMethods.findIndex(pm => pm.id === paymentMethodId);

      if (index !== -1) {
        setPaymentMethods([
          ...paymentMethods.slice(0, index),
          ...paymentMethods.slice(index + 1)
        ]);
      }

      if (paymentMethods.length < 1 && hasMorePaymentMethods) {
        loadPaymentMethods();
      }

      return setIsDeleteBusy(null);
    }

    setIsDeleteBusy(null);
  }

  const handleOnDeletePaymentMethod = (paymentMethod: PaymentMethod) => {
    alertController.open({
      icon: "info",
      title: "Confirm delete payment method",
      message: (
        <>
          Are you sure you want to delete the payment method ending in ••••
          <strong>{paymentMethod.card.last4}</strong>?
        </>
      ),
      buttonText: "Delete",
      buttonVariant: "danger",
      onOkay: () => {
        deletePaymentMethod(paymentMethod.id);
      },
      cancelButtonText: "Cancel"
    });
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);

    await loadPaymentMethods(paymentMethods[paymentMethods.length - 1].id);

    setIsLoadingMore(false);
  };

  const handlePaymentMethodAdded = (pm: PaymentMethod) => {
    setPaymentMethods([pm, ...paymentMethods]);

    setIsShowAddPayMethodModal(false);
  };

  useEffect(() => {
    if (!paymentMethodsLoaded) {
      loadPaymentMethods();
    }
  }, [paymentMethodsLoaded, loadPaymentMethods]);

  return (
    <>
      <div className={styles.billingPaymentMethods}>
        <button
          className="btn btn-sm btn-light border px-3 mb-3 d-block ms-auto"
          onClick={() => setIsShowAddPayMethodModal(true)}
        >
          <SvgIcon name="plus" className="me-1" />
          Add payment method
        </button>

        <div className={styles.paymentMethods}>
          {!paymentMethodsLoading && paymentMethods.length < 1 && (
            <div className="bg-white text-center shadow-sm rounded-sm p-5 w-100">
              No payment method has been added
            </div>
          )}
          {!isLoadingMore && paymentMethodsLoading
            ? Array.from({ length: 4 }, (_, i) => (
                <div key={i + 1} className={styles.payMethodItem}>
                  <div className={styles.payMethodMain}>
                    <div className={styles.payMethodIconLoading}></div>
                    <div className={styles.payMethodDetails}>
                      <div className={styles.payMethodLabelLoading}></div>
                      <div className={styles.payMethodExpiresLoading}></div>
                    </div>
                  </div>
                </div>
              ))
            : paymentMethods.map(pm => (
                <div key={pm.id} className={styles.payMethodItem}>
                  <div className={styles.payMethodMain}>
                    <SvgIcon
                      name={pm.card.brand}
                      className={styles.payMethodIcon}
                    />
                    <div className={styles.payMethodDetails}>
                      <div className={styles.payMethodLabel}>
                        •••• {pm.card.last4}
                      </div>
                      <div className={styles.payMethodExpiry}>
                        Expires {pm.card.expMonth}/{pm.card.expYear}
                      </div>
                    </div>
                  </div>
                  <div className={styles.payMethodFooter}>
                    {pm.isDefault ? (
                      <div className={clsx("badge", styles.defaultBadge)}>
                        Default
                      </div>
                    ) : (
                      <button
                        className={clsx("btn", styles.setDefaultBtn)}
                        disabled={!!isDefaultBusy}
                        onClick={() => setDefaultPaymentMethod(pm.id)}
                      >
                        {isDefaultBusy === pm.id ? (
                          <Spinner color="primary" />
                        ) : (
                          "Set as default"
                        )}
                      </button>
                    )}
                    <button
                      className={clsx("btn", styles.deleteBtn)}
                      disabled={isDeleteBusy === pm.id}
                      onClick={() => handleOnDeletePaymentMethod(pm)}
                    >
                      {isDeleteBusy === pm.id ? (
                        <Spinner color="primary" />
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </div>
                </div>
              ))}
        </div>
        {hasMorePaymentMethods && (
          <button
            className="btn btn-sm btn-light border w-100 mt-4"
            disabled={isLoadingMore}
            onClick={handleLoadMore}
          >
            {isLoadingMore ? <Spinner color="primary" /> : "Load more"}
          </button>
        )}
      </div>

      <AddPaymentMethodModal
        show={isShowAddPayMethodModal}
        onClose={() => setIsShowAddPayMethodModal(false)}
        onAdded={handlePaymentMethodAdded}
      />
    </>
  );
}
