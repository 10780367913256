import styles from "./style.module.scss";

export default function SecuritySettings() {
  return (
    <div className={styles.securitySettings}>
      <h3>Security Settings</h3>
      <p>Manage your account security settings.</p>

      <form method="POST" className="">
        <h4 className="mt-5 mb-4 text-primary">Change password</h4>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="currentPasswordInput" className="form-label">
                Current password
              </label>
              <input
                type="password"
                className="form-control"
                id="currentPasswordInput"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="newPasswordInput" className="form-label">
                New password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPasswordInput"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPasswordInput" className="form-label">
                Confirm password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPasswordInput"
              />
            </div>
          </div>
        </div>

        <div className="text-end">
          <button className="btn btn-primary text-white px-5 rounded-pill">
            Change password
          </button>
        </div>
      </form>
    </div>
  );
}
