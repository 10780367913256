import clsx from "clsx";
import styles from "./style.module.scss";

interface LoadingOrbsProps {
  className?: string;
  text?: string;
  variant?: "1" | "2" | "3" | "4" | "5" | "6";
}

export default function LoadingOrbs({
  variant = "1",
  className,
  text = "Loading"
}: LoadingOrbsProps) {
  return (
    <div
      className={clsx(className, styles.container, styles[`palette${variant}`])}
    >
      <div className={clsx(styles.blobs)}>
        <svg viewBox="0 0 1200 1200">
          <g className={clsx(styles.blob, styles.blob1)}>
            <path />
          </g>
          <g className={clsx(styles.blob, styles.blob2)}>
            <path />
          </g>
          <g className={clsx(styles.blob, styles.blob3)}>
            <path />
          </g>
          <g className={clsx(styles.blob, styles.blob4)}>
            <path />
          </g>
          <g className={clsx(styles.blob, styles.blob1, styles.alt)}>
            <path />
          </g>
          <g className={clsx(styles.blob, styles.blob2, styles.alt)}>
            <path />
          </g>
          <g className={clsx(styles.blob, styles.blob3, styles.alt)}>
            <path />
          </g>
          <g className={clsx(styles.blob, styles.blob4, styles.alt)}>
            <path />
          </g>
        </svg>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
}
