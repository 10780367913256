import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { AuthUser } from "support/types";

export interface AuthState {
  isBusy: boolean;
  isAuthenticated: boolean;
  user: AuthUser | null;
  isSessionExpired: boolean;
}

const initialState: AuthState = {
  isBusy: false,
  isAuthenticated: false,
  user: null,
  isSessionExpired: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearUser: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = null;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<AuthUser>) => {
      state.user = action.payload;
    },

    setIsBusy: (state, action: PayloadAction<boolean>) => {
      state.isBusy = action.payload;
    },

    login: (state, action: PayloadAction<AuthUser>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },

    logout: state => {
      state.user = null;
      state.isAuthenticated = false;
    }
  }
});

export const { setIsBusy, clearUser, setUser, login, logout } =
  authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.user)`
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
