import { Outlet } from "react-router-dom";

import SettingsNav from "components/Settings/Nav";

import styles from "./style.module.scss";

export default function Settings() {
  return (
    <div className={styles.settingsRow}>
      <div className={styles.navCol}>
        <h1>Settings</h1>

        <SettingsNav />
      </div>

      <div className={styles.mainCol}>
        <Outlet />
      </div>
    </div>
  );
}
