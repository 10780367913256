import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import PageLoading from "components/General/PageLoading";

import { useAuth } from "contexts/AuthContext";

export const AuthProtectedRoute = () => {
  const { isAuthLoading, isAuthenticated } = useAuth();

  if (isAuthLoading) {
    return <PageLoading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
