import { Link } from "react-router-dom";

export default function Channels() {
  return (
    <div>
      <h1>Channels</h1>

      <div className="shadow p-5 text-center rounded mt-4">
        <h1 className="mt-5 pt-5 fw-bold">Coming Soon!</h1>
        <p className="mb-5 pb-5 text-muted fw-normal">
          Connect your social media channels and automatically publish generated
          videos to them using our <Link to="/series">Series</Link> feature.
        </p>
      </div>
    </div>
  );
}
