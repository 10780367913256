import InitialsAvatar from "components/General/InitialsAvatar";
import SvgIcon from "components/General/SvgIcon";

import { useAuth } from "contexts/AuthContext";

import styles from "./style.module.scss";
import clsx from "clsx";

export default function AccountSettings() {
  const { user } = useAuth();

  return (
    <div className={styles.accountSettings}>
      <h3>Account Settings</h3>
      <p>Keep your account information up to date.</p>

      <div className="d-flex justify-content-center my-4">
        <div className={styles.avatarWrap}>
          <InitialsAvatar
            name={`${user?.firstName} ${user?.lastName}`}
            size={120}
          />
          <button className={clsx("btn", styles.updatePhotoBtn)}>
            <SvgIcon name="camera" />
          </button>
        </div>
      </div>

      <form method="POST" className="">
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="firstNameInput" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstNameInput"
                defaultValue={user?.firstName}
              />
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="lastNameInput" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastNameInput"
                defaultValue={user?.lastName}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <label htmlFor="emailInput" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                defaultValue={user?.email}
                className="form-control"
                id="emailInput"
                aria-describedby="emailHelp"
                readOnly
              />
              <div id="emailHelp" className="form-text">
                Your email address will need to be re-verified if it is changed.
              </div>
            </div>
          </div>
        </div>

        <div className="text-end">
          <button className="btn btn-primary text-white px-5 rounded-pill">
            Update account
          </button>
        </div>
      </form>
    </div>
  );
}
