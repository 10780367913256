import { useState } from "react";
import {
  BubbleMenu,
  FloatingMenu,
  EditorContent,
  useEditor
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";

import SelectVoiceDropdown from "components/General/SelectVoiceDropdown";

import "./style.scss";
import Scrollbar from "components/General/Scrollbar";
import clsx from "clsx";

const ScriptEditor = ({
  content = "",
  maxLength = 1700
}: {
  content?: string;
  maxLength?: number;
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount.configure({
        limit: maxLength
      })
    ],
    content
  });
  const [voiceId, setVoiceId] = useState("felie");

  const handleVoiceChange = (voiceId: string) => {
    voiceId && setVoiceId(voiceId);
  };

  const count = editor?.storage.characterCount.characters();

  return (
    <div
      className={clsx("script-editor-wrap", {
        focused: editor && editor.isFocused
      })}
    >
      <div className="header-toolbar">
        <div className="toolbar-col">
          <div className="voice-selector-wrap">
            <div className="voice-selector-label">Voice:</div>
            <SelectVoiceDropdown value={voiceId} onChange={handleVoiceChange} />
          </div>
        </div>
        <div className="toolbar-col">
          <div className="toolbar-char-limit">
            {count?.toLocaleString()} / {maxLength?.toLocaleString()}
          </div>
        </div>
      </div>
      {editor && (
        <>
          <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100, maxWidth: "none", arrow: true }}
          >
            <div className="bubble-menu">
              <button>Rephrase</button>
              <button>Shorten</button>
              <button>Expand</button>
              <button>Simplify</button>
              <button>Fix Spelling</button>
            </div>
          </BubbleMenu>

          <FloatingMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <div className="floating-menu">
              <button>Continue</button>
              <button>Rewrite</button>
            </div>
          </FloatingMenu>
        </>
      )}
      <Scrollbar className="editor-scroll">
        <EditorContent editor={editor} />
      </Scrollbar>
    </div>
  );
};

export default ScriptEditor;
