import { Plan } from "./types";

export const getPlanPrice = (plan: Plan, frequency: string) => {
  const annualPrice = plan.prices.find(p => p.interval === "annually");
  const monthlyPrice = plan.prices.find(p => p.interval === "monthly");

  return frequency === "monthly" ? monthlyPrice : annualPrice;
};

export const getPlanPriceRate = (plan: Plan, frequency: string) => {
  const price = getPlanPrice(plan, frequency);

  return price?.amountMonthly;
};

export const getPriceTotal = (rate: number, frequency: string) => {
  return frequency === "annually" ? rate * 12 : rate;
};

export const formatMoney = (amount: number, currency: string = "usd") => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency
  });

  return formatter.format(amount);
};
