import clsx from "clsx";
import { NavLink } from "react-router-dom";

import styles from "./style.module.scss";

import SvgIcon from "components/General/SvgIcon";

const navigations = [
  {
    label: "Account Settings",
    description: "Personal Information and Email",
    route: "/settings",
    icon: "user-filled",
    isEnd: true
  },
  {
    label: "Security",
    description: "Password and account security",
    route: "/settings/security",
    icon: "security",
    isEnd: false
  },
  {
    label: "Billing",
    description: "Subscription plans and billing",
    route: "/settings/billing",
    icon: "credit-card",
    isEnd: false
  },
  {
    label: "Notifications",
    description: "Account notification preferences",
    route: "/settings/notifications",
    icon: "notification-filled",
    isEnd: false
  }
];

export default function SettingsNav() {
  return (
    <nav className={styles.settingsNav}>
      <ul className={styles.navList}>
        {navigations.map(nav => (
          <li key={nav.route}>
            <NavLink
              to={nav.route}
              className={({ isActive }) =>
                clsx(styles.navLink, {
                  [styles.activeLink]: isActive
                })
              }
              end={nav.isEnd}
            >
              {({ isActive }) => (
                <>
                  <div
                    className={clsx(styles.iconWrap, {
                      [styles.iconWrapActive]: isActive
                    })}
                  >
                    <SvgIcon name={nav.icon} className={styles.navIcon} />
                  </div>
                  <div className={styles.content}>
                    <div
                      className={clsx(styles.navlabel, {
                        [styles.labelActive]: isActive
                      })}
                    >
                      {nav.label}
                    </div>
                    <div className={styles.description}>{nav.description}</div>
                  </div>
                  <div
                    className={clsx(styles.chevronWrap, {
                      [styles.chevronActive]: isActive
                    })}
                  >
                    <SvgIcon name="chevron-right" />
                  </div>
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}
