import clsx from "clsx";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import { Charge } from "support/types";
import { formatMoney } from "support/helpers";
import toast from "support/toast";

import { Link } from "react-router-dom";
import Spinner from "components/General/Spinner";
import useBackend from "hooks/useBackend";

import styles from "./style.module.scss";

const formatDate = (timestamp: number) => {
  return moment.unix(timestamp).format("MMMM D, YYYY, h:mm A");
};

function capitalizeFirstLetter(str: string) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function BillingHistory() {
  const [charges, setCharges] = useState<Charge[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { get } = useBackend();

  const loadHistory = useCallback(
    async (signal?: AbortSignal, startAfter?: string) => {
      let url = `/billing/history`;

      if (startAfter) {
        url += `?cur=${startAfter}`;
      }

      return get(url, { signal }).then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            return jsonResonponse.data;
          } catch (error) {
            console.log(error);

            return false;
          }
        }
      });
    },
    [get]
  );

  const handleLoadMore = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsLoadingMore(true);

    const data = await loadHistory(signal, charges[charges.length - 1].id);

    if (!data) {
      toast.error("An error occured while processing your request.");
      return setIsLoadingMore(false);
    }

    setHasMore(data.hasMore);

    setCharges([...charges, ...data.charges]);

    setIsLoadingMore(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsLoading(true);

    loadHistory(signal)
      .then(data => {
        setHasMore(data.hasMore);

        setCharges(data.charges);

        setIsLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [loadHistory]);

  return (
    <div className={styles.billingHistory}>
      {!isLoadingMore &&
        isLoading &&
        Array.from({ length: 11 }, (_, i) => (
          <div key={i + 1} className={styles.tableLoadingItem}>
            <div className={styles.tableLoadingItemCol}></div>
            <div className={styles.tableLoadingItemCol}></div>
            <div className={styles.tableLoadingItemCol}></div>
            <div className={styles.tableLoadingItemCol}></div>
          </div>
        ))}

      {!isLoading && charges.length < 1 && (
        <div className="bg-white text-center shadow-sm rounded-sm p-5">
          No transactions yet.
        </div>
      )}

      {charges.length > 0 && (
        <>
          <p>Showing transactions within the past 12 months</p>

          <table className={clsx("table", styles.chargesTable)}>
            <thead>
              <tr>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {charges.map(charge => (
                <tr key={charge.id}>
                  <td>
                    <span className={styles.chargeAmount}>
                      {formatMoney(charge.amount / 100)}
                    </span>{" "}
                    {charge.currency.toLocaleUpperCase()}
                  </td>
                  <td>
                    <div className="badge text-bg-success">
                      {capitalizeFirstLetter(charge.status)}
                    </div>
                  </td>
                  <td>{formatDate(charge.created)}</td>
                  <td>
                    {charge.receiptUrl && (
                      <Link
                        to={charge.receiptUrl}
                        target="_blank"
                        className="btn btn-sm btn-link"
                      >
                        Receipt
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {hasMore && (
            <button
              className="btn btn-sm btn-light border w-100 mt-2"
              disabled={isLoadingMore}
              onClick={handleLoadMore}
            >
              {isLoadingMore ? <Spinner color="primary" /> : "Load more"}
            </button>
          )}
        </>
      )}
    </div>
  );
}
