import { Modal } from "components/General/Modal";

import { useAlert } from "contexts/AlertContext";

import SvgIcon from "../SvgIcon";

import { ReactComponent as AlertPolygon } from "assets/vectors/alert-polygon.svg";

import "./style.scss";
import clsx from "clsx";

export function AlertModal() {
  const { show, onClose, options } = useAlert();

  const handleOnOkay = () => {
    if (typeof options.onOkay === "function") {
      options.onOkay();
    }

    onClose();
  };

  const handleOnCancel = () => {
    if (typeof options.onCancel === "function") {
      options.onCancel();
    }

    onClose();
  };

  const icon =
    options.icon === "success"
      ? "check"
      : options.icon === "error"
      ? "close"
      : "info-i";

  return (
    <Modal show={show} className="alert-modal" onClose={onClose}>
      <div className={clsx("alert-icon-wrap", options.icon || "info")}>
        <AlertPolygon className="alert-polygon" />
        <SvgIcon name={icon} className="alert-icon" />
      </div>

      {options.title && <h4 className="alert-title">{options.title}</h4>}
      {options.message && (
        <div className="alert-message">{options.message}</div>
      )}

      <div className="alert-btns">
        {options.cancelButtonText && (
          <button
            className="btn btn-light px-4 border fw-bold"
            onClick={handleOnCancel}
          >
            {options.cancelButtonText}
          </button>
        )}

        <button
          className={`btn btn-${
            options.buttonVariant || "primary"
          } px-4 text-white fw-bold`}
          onClick={handleOnOkay}
        >
          {options.buttonText || "Okay"}
        </button>
      </div>
    </Modal>
  );
}
