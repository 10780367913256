import AuthSubmitButton from "components/Auth/AuthSubmitButton";
import PasswordField from "components/General/PasswordField";
import AuthTitle from "components/Auth/AuthTitle";
import AuthDescription from "components/Auth/AuthDescription";
import { FormEvent, useState } from "react";
import { ValidationErrors } from "support/types";
import { z } from "zod";
import { useNavigate, useParams } from "react-router-dom";
import toast from "support/toast";
import Spinner from "components/General/Spinner";
import useBackend from "hooks/useBackend";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { token } = useParams();
  const navigate = useNavigate();

  const { post } = useBackend();

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);

    const schema = z.object({
      password: z.string().min(1, { message: "Password is required" }),
      password_confirmation: z
        .string()
        .min(1, { message: "Password is required" }),
      token: z.string().min(1)
    });

    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) {
      return;
    }

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsLoading(true);

      try {
        const res = await post("/auth/reset-password", {
          body: zResult.data
        });

        const jsonResponse = await res.json();

        if (!res.ok) {
          if (jsonResponse.type === "validation") {
            setErrors(jsonResponse.errors);
          } else {
            toast.error(jsonResponse.message);
          }

          setIsLoading(false);
        } else if (jsonResponse.status === 200) {
          setIsLoading(false);
          navigate("/");
          toast.success("Password reset successful");
        }
      } catch (error) {
        setIsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  return (
    <>
      <AuthTitle>Reset your Password</AuthTitle>

      <AuthDescription>
        Please enter your new password below to continue.
      </AuthDescription>

      <form onSubmit={e => handleSubmit(e)} onChange={e => handleFormChange(e)}>
        <PasswordField
          icon="lock-alt"
          placeholder="New Password"
          className="my-4"
          name="password"
          errors={errors.password}
        />
        <PasswordField
          icon="lock-alt"
          placeholder="Confirm Password"
          className="my-4"
          name="password_confirmation"
          errors={errors.password_confirmation}
        />

        <input type="hidden" name="token" value={token} />

        <AuthSubmitButton>
          {!isLoading ? "Continue" : <Spinner />}
        </AuthSubmitButton>
      </form>
    </>
  );
}
