import { useState } from 'react';

import Inputfield, { InputFieldProps } from '../InputField';
import SvgIcon from '../SvgIcon';

import styles from './style.module.scss';

export default function PasswordField(props: InputFieldProps) {
  const [isShow, setIsShow] = useState(false);

  const toggleShow = () => {
    setIsShow(!isShow);
  };

  return (
    <Inputfield
      {...props}
      inputClassName={styles.input}
      type={isShow ? 'text' : 'password'}
    >
      <button
        type="button"
        className={styles.showHideButton}
        onClick={() => toggleShow()}
      >
        <SvgIcon
          name={isShow ? 'eye' : 'eye-slash'}
          className={styles.rightIcon}
        />
      </button>
    </Inputfield>
  );
}
