import clsx from "clsx";
import { MouseEvent } from "react";
import Spinner from "components/General/Spinner";
import SvgIcon from "components/General/SvgIcon";
import { DropdownItem } from "support/types";

import "./style.scss";

interface SplitDropdownProps {
  items: DropdownItem[];
  active: DropdownItem;
  loading?: boolean;
  onClick?: (e: MouseEvent) => void;
  onSelectAction?: (action: DropdownItem, e: MouseEvent) => void;
}

const SplitDropdown = ({
  items,
  active,
  loading,
  onClick,
  onSelectAction
}: SplitDropdownProps) => {
  const handleOnSelectAction = (action: DropdownItem, e: MouseEvent) => {
    e.preventDefault();

    if (action.disabled) {
      return e.stopPropagation();
    }

    if (typeof onSelectAction === "function") {
      onSelectAction(action, e);
    }
  };

  const handleOnClick = (e: MouseEvent) => {
    if (typeof onClick === "function") {
      onClick(e);
    }
  };

  return (
    <div className="splitButtonsWrap">
      <button className="mainBtn fw-bold" onClick={handleOnClick}>
        {!loading ? active.label : <Spinner />}
      </button>
      <div className="dropdown split-dropdown">
        <button
          className="dropdown-toggle chevronBtn"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-offset="0,10"
          aria-expanded="false"
        >
          <SvgIcon name="chevron-down" className="chevronIcon" />
        </button>
        <ul className="dropdown-menu dropdown-menu-end dropdownMenu">
          {items.map(action => (
            <li
              key={action.id}
              className={clsx("actionItem", {
                active: action.id === active.id,
                disabled: action.disabled
              })}
            >
              <a
                className="dropdown-item"
                href="#video"
                onClick={e => handleOnSelectAction(action, e)}
              >
                {action.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SplitDropdown;
