import React from "react";

interface InitialsAvatarProps {
  name: string;
  size?: number;
  bgColor?: string;
  textColor?: string;
}

const InitialsAvatar: React.FC<InitialsAvatarProps> = ({
  name,
  size = 50,
  bgColor = "#007bff",
  textColor = "#ffffff"
}) => {
  const getInitials = (name: string) => {
    const words = name.split(" ");
    const initials =
      words.length > 1
        ? `${words[0].charAt(0)}${words[words.length - 1].charAt(0)}`
        : words[0].charAt(0);
    return initials.toUpperCase();
  };

  const initials = getInitials(name);

  const avatarStyle: React.CSSProperties = {
    backgroundColor: bgColor,
    color: textColor,
    width: size,
    height: size,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontSize: size / 2 - 5,
    fontWeight: "bold",
    textTransform: "uppercase"
  };

  return <div style={avatarStyle}>{initials}</div>;
};

export default InitialsAvatar;
