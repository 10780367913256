import clsx from "clsx";

import styles from "./style.module.scss";

import { ReactComponent as VideoVector } from "assets/vectors/video.svg";
import { ReactComponent as SeriesVector } from "assets/vectors/series.svg";
import { ReactComponent as ChannelsVector } from "assets/vectors/channels.svg";

import { useAuth } from "contexts/AuthContext";

export default function WelcomeBox() {
  const { user } = useAuth();

  return (
    <div className={styles.welcomeBox}>
      <div className={styles.boxContent}>
        <h1 className={styles.title}>
          Welcome {user && `${user.firstName} ${user.lastName}`}
        </h1>
        <p className={styles.description}>
          Let's create something awesome today!
        </p>
      </div>
      <div className={styles.boxStats}>
        <div className={clsx(styles.statBox, styles.lightBlue)}>
          <div className={styles.statNumber}>23</div>
          <div className={styles.statLabel}>Videos</div>
          <VideoVector />
        </div>
        <div className={clsx(styles.statBox, styles.lightPurple)}>
          <div className={styles.statNumber}>15</div>
          <div className={styles.statLabel}>Series</div>
          <SeriesVector />
        </div>
        <div className={clsx(styles.statBox, styles.lightRed)}>
          <div className={styles.statNumber}>17</div>
          <div className={styles.statLabel}>Channels</div>
          <ChannelsVector />
        </div>
      </div>
    </div>
  );
}
