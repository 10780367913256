import { io, Socket } from "socket.io-client";

class SocketClient {
  private static instance: SocketClient;
  private socket: Socket;

  private constructor() {
    this.socket = io(`${process.env.REACT_APP_API_URL}`, {
      withCredentials: true
    });

    this.socket.on("connect", () => {
      console.log(`Connected with id: ${this.socket.id}`);
    });
  }

  public static getInstance(): SocketClient {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient();
    }
    return SocketClient.instance;
  }

  public static getSocket(): Socket {
    const instance = SocketClient.getInstance();

    return instance.socket;
  }

  public subscribeToChannel(channel: string): void {
    this.socket.emit("subscribe", { channel });
  }

  public unsubscribeFromChannel(channel: string): void {
    this.socket.emit("unsubscribe", { channel });
  }
}

export default SocketClient;
