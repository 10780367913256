import clsx from "clsx";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "bootstrap";

import { useAuth } from "contexts/AuthContext";

import Spinner from "components/General/Spinner";
import SvgIcon from "components/General/SvgIcon";
import InitialsAvatar from "components/General/InitialsAvatar";

import styles from "./style.module.scss";

type UserDropdownProps = {
  className?: string;
};

export default function UserDropdown(props: UserDropdownProps) {
  const dropdownRef = useRef<HTMLAnchorElement>(null);

  const { isBusy: isAuthBusy, user, handleLogout } = useAuth();

  useEffect(() => {
    let dropdown: InstanceType<typeof Dropdown>;

    if (dropdownRef.current) {
      dropdown = new Dropdown(dropdownRef.current, {});
    }

    // Cleanup side effects
    return () => {
      dropdown && dropdown.dispose();
    };
  }, []);

  const onLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isAuthBusy) {
      return;
    }

    e.preventDefault();

    handleLogout();
  };

  return (
    <div className={clsx("dropdown", props.className)}>
      <a
        ref={dropdownRef}
        href="#toggle"
        className={styles.toggleWrap}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-offset="17,15"
      >
        <div className={styles.avatarPhotoWrap}>
          {user?.firstName && !user?.profileUrl ? (
            <InitialsAvatar
              name={`${user.firstName} ${user.lastName}`}
              size={35}
            />
          ) : (
            <img
              className={styles.avatarPhoto}
              src={user?.profileUrl}
              alt="Profile Avatar"
            />
          )}
        </div>
        <div className={styles.toggleContent}>
          <div className={styles.toggleName}>
            {user && `${user.firstName} ${user.lastName}`}
          </div>
          <div className={styles.togglePosition}>Content Creator</div>
        </div>
        <SvgIcon name="chevron-down" className={styles.chevron} />
      </a>

      <ul
        className={clsx("dropdown-menu dropdown-menu-end", styles.dropdownMenu)}
      >
        <li>
          <Link className="dropdown-item" to="/settings">
            Account Settings
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/settings/billing">
            Plans & Billing
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <a
            href="#logout"
            className="dropdown-item"
            onClick={e => onLogout(e)}
          >
            {isAuthBusy && <Spinner color="primary" className="me-2" />}
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
}
