import { z } from "zod";
import Select from "react-select";
import { FormEvent, useCallback, useState } from "react";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { useAuth } from "contexts/AuthContext";

import toast from "support/toast";
import countries from "support/coutries";
import { PaymentMethod } from "support/types";

import SvgIcon from "components/General/SvgIcon";
import { Modal } from "components/General/Modal";
import Spinner from "components/General/Spinner";

import "./style.scss";
import useBackend from "hooks/useBackend";

interface AddPaymentMethodModalProps {
  show: boolean;
  onClose: () => void;
  onAdded?: (pm: PaymentMethod, isDefault: boolean) => void;
}

export function AddPaymentMethodModal({
  show,
  onClose,
  onAdded
}: AddPaymentMethodModalProps) {
  // Access Stripe and Elements hooks
  const stripe = useStripe();
  const elements = useElements();

  const [isContinueLoading, setIsContinueLoading] = useState(false);

  const { post } = useBackend();

  const { user } = useAuth();

  async function fetchSetupIntentSecret() {
    const response = await post("/billing/setup");

    if (response.ok) {
      const data = await response.json();
      return data.data.clientSecret;
    }

    return false;
  }

  async function finishPaymentMethodSetup(
    paymentMethodId: string,
    isSetDefault: boolean
  ) {
    const response = await post("/billing/setup/finish", {
      body: { paymentMethodId, isSetDefault }
    });

    if (response.ok) {
      const data = await response.json();
      return data.data.paymentMethod;
    }

    return false;
  }

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);

    const schema = z
      .object({
        name: z
          .string()
          .min(1, { message: "Cardholder Name is a required field." }),
        country: z
          .string()
          .min(1, { message: "Please select your billing address country" }),
        address1: z
          .string()
          .min(1, { message: "Billing address is a required field" }),
        address2: z.string().optional(),
        city: z.string().optional(),
        postalCode: z.string().optional(),
        state: z.string().optional(),
        // isSetDefault: z.boolean().optional()
        isSetDefault: z.preprocess(val => {
          if (typeof val === "string") {
            return val === "on";
          }

          return val;
        }, z.boolean().optional())
      })
      .refine(
        data => {
          if (
            (data.city && data.postalCode) ||
            (data.city && data.state) ||
            (data.state && data.postalCode)
          ) {
            return true;
          }

          return false;
        },
        {
          message: "More information is required for your billing address",
          path: ["address1"] // Field that will receive the error message
        }
      );

    return schema.safeParse(Object.fromEntries(data));
  };

  const handleOnContinue = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!user) {
      return;
    }

    // Ensure Stripe.js has loaded
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const zResult = validateForm(e);

    if (!zResult.success) {
      const errors = Object.values(zResult.error.flatten().fieldErrors);

      return toast.error(errors[0][0]);
    }

    const billingData = zResult.data;

    setIsContinueLoading(true);

    const clientSecret = await fetchSetupIntentSecret();

    if (!clientSecret) {
      setIsContinueLoading(false);

      return toast.error(
        "Unable to complete your request. Trying reloading the page. If the issue persists please contact us."
      );
    }

    if (cardElement) {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: billingData.name,
              address: {
                country: billingData.country,
                line1: billingData.address1,
                line2: billingData.address2,
                city: billingData.city,
                state: billingData.state,
                postal_code: billingData.postalCode
              }
            }
          }
        }
      );

      if (error) {
        toast.error(error.message || "An error occurred");
        setIsContinueLoading(false);
      } else if (setupIntent.status === "succeeded") {
        const paymentMethod = await finishPaymentMethodSetup(
          setupIntent.payment_method as string,
          billingData.isSetDefault as boolean
        );

        if (typeof onAdded === "function") {
          onAdded(paymentMethod, billingData.isSetDefault as boolean);
        }

        setIsContinueLoading(false);
      }
    }
  };

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const headerContent = (
    <div>
      <h5 className="modal-title">Add payment method</h5>
      <p className="modal-description">
        Add your credit card details below. This card will be saved to your
        account and can be removed at any time.
      </p>

      <button type="button" className="btn-close" onClick={handleOnClose}>
        <SvgIcon name="close-circle" />
      </button>
    </div>
  );

  return (
    <Modal
      show={show}
      className="add-payment-method-modal"
      headerContent={headerContent}
      onClose={handleOnClose}
    >
      <form method="POST" onSubmit={handleOnContinue}>
        <div className="mb-4">
          <label htmlFor="cardInfo" className="top-up-label">
            Card Information
          </label>
          <CardElement
            id="cardInfo"
            className="form-control"
            options={{ disableLink: true, hidePostalCode: true }}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="name" className="top-up-label">
            Name on card
          </label>
          <input id="name" name="name" className="form-control" />
        </div>

        <div className="mb-2">
          <label htmlFor="country" className="top-up-label">
            Billing address
          </label>
          <Select
            name="country"
            id="country"
            components={{ IndicatorSeparator: () => null }}
            classNames={{
              control: state => (state.isFocused ? "rselect-focus" : "rselect")
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: 32
              }),
              dropdownIndicator: (baseStyles, props) => ({
                ...baseStyles,
                padding: "6px"
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 8,
              spacing: {
                ...theme.spacing,
                controlHeight: 32
              },
              colors: {
                ...theme.colors,
                primary25: "var(--bs-gray-100)",
                primary50: "var(--bs-gray-100)",
                primary: "var(--bs-primary)"
              }
            })}
            placeholder="Country"
            options={countries.map(c => ({ label: c.name, value: c.code }))}
          />
        </div>

        <div className="mb-2">
          <input
            name="address1"
            className="form-control"
            placeholder="Address line 1"
          />
        </div>

        <div className="mb-2">
          <input
            name="address2"
            className="form-control"
            placeholder="Address line 2"
          />
        </div>

        <div className="row gx-2 mb-2">
          <div className="col">
            <input name="city" className="form-control" placeholder="City" />
          </div>
          <div className="col">
            <input
              name="postalCode"
              className="form-control"
              placeholder="Postal code"
            />
          </div>
        </div>

        <div className="mb-3">
          <input
            name="state"
            className="form-control"
            placeholder="State, country, province, or region"
          />
        </div>

        <div className="form-check mb-4">
          <input
            id="isSetDefault"
            name="isSetDefault"
            type="checkbox"
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor="isSetDefault">
            Set as default payment method
          </label>
        </div>

        <div className="text-end mt-3">
          <button
            type="button"
            className="btn btn-light btn-sm border me-2 px-3"
            disabled={isContinueLoading}
            onClick={handleOnClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-sm text-white fw-bold px-3"
            disabled={isContinueLoading}
          >
            {isContinueLoading ? <Spinner /> : "Add payment method"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
