import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { Outlet } from "react-router-dom";

import PageLoading from "components/General/PageLoading";

export const GuestRoute = () => {
  const { isAuthLoading, isAuthenticated } = useAuth();

  if (isAuthLoading) {
    return <PageLoading />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};
