import { Route, Routes } from "react-router-dom";

import { GuestRoute } from "./GuestRoute";
import { AuthProtectedRoute } from "./AuthProtectedRoute";
import { EmailVerifyProtectedRoute } from "./EmailVerifyProtectedRoute";

import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Forgot from "../pages/Forgot";
import ResetPassword from "../pages/ResetPassword";

import AuthLayout from "layouts/Auth";
import DashboardLayout from "layouts/Dashboard";

import VerifyEmail from "pages/VerifyEmail";
import EmailTokenVerify from "pages/VerifyEmail/EmailTokenVerify";

import Dashboard from "pages/Dashboard";
import VideosPage from "pages/Videos";
import VideoEditPage from "pages/Videos/Edit";
import Series from "pages/Series";
import Channels from "pages/Channels";

import Settings from "pages/Settings";
import AccountSettings from "pages/Settings/Account";
import SecuritySettings from "pages/Settings/Security";

import BillingSettings from "pages/Settings/Billing";
import BillingOverview from "pages/Settings/Billing/Overview";
import BillingPaymentMethods from "pages/Settings/Billing/PaymentMethods";
import BillingHistory from "pages/Settings/Billing/History";
import { On3DSComplete } from "pages/Settings/Billing/On3DSComplete";

import NotificationSettings from "pages/Settings/Notifications";

export default function AppRoutes() {
  return (
    <Routes>
      <Route element={<GuestRoute />}>
        <Route path="/" element={<AuthLayout />}>
          <Route path="" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
        </Route>
      </Route>

      <Route path="verify/email/:token" element={<EmailTokenVerify />} />

      <Route path="billing/3DSComplete" element={<On3DSComplete />} />

      <Route element={<AuthProtectedRoute />}>
        <Route path="verify" element={<VerifyEmail />} />

        <Route element={<EmailVerifyProtectedRoute />}>
          <Route element={<DashboardLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="videos" element={<VideosPage />} />
            <Route path="videos/:id" element={<VideoEditPage />} />

            <Route path="series" element={<Series />} />
            <Route path="channels" element={<Channels />} />

            <Route path="settings" element={<Settings />}>
              <Route path="" element={<AccountSettings />} />
              <Route path="security" element={<SecuritySettings />} />
              <Route path="billing" element={<BillingSettings />}>
                <Route path="" element={<BillingOverview />} />
                <Route
                  path="payment-methods"
                  element={<BillingPaymentMethods />}
                />
                <Route path="history" element={<BillingHistory />} />
              </Route>
              <Route path="notifications" element={<NotificationSettings />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}
