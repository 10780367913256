import clsx from "clsx";

import SvgIcon from "components/General/SvgIcon";
import GenereateInput from "components/Dashboard/GenerateInput";

import { useBilling } from "contexts/BillingContext";
import { useAuth } from "contexts/AuthContext";

import UserDropdown from "../UserDropdown";

import styles from "./style.module.scss";

export default function Navbar() {
  const { showTopUpModal } = useBilling();
  const { user } = useAuth();

  return (
    <nav className={styles.navbar}>
      <div className={clsx("container", styles.container)}>
        <GenereateInput />
        <div className={styles.availableCreditsWrap}>
          <div className={styles.availableCredits}>
            <SvgIcon name="trophy" className={styles.trophyIcon} />
            {user?.credits} Credits
            <button className={styles.creditTopup} onClick={showTopUpModal}>
              Top up
            </button>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.navIcons}>
            <button className={styles.iconButtons}>
              <SvgIcon name="help-circle" className={styles.buttonIcon} />
            </button>
            <button className={styles.iconButtons}>
              <div className={styles.unreadNotification}>
                Unread Notification
              </div>
              <SvgIcon name="bell" className={styles.buttonIcon} />
            </button>
          </div>
          <UserDropdown />
        </div>
      </div>
    </nav>
  );
}
