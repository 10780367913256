import clsx from "clsx";
import { NavLink, Outlet } from "react-router-dom";

import styles from "./style.module.scss";

const navs = [
  {
    label: "Overview",
    route: ""
  },
  {
    label: "Payment Methods",
    route: "payment-methods"
  },
  {
    label: "Billing History",
    route: "history"
  }
];

export default function BillingSettings() {
  return (
    <div className={styles.billingSettings}>
      <h3>Plans & Billing</h3>
      <p>Upgrade or manage your current subscription.</p>

      <div className={styles.subnav}>
        {navs.map(nav => (
          <NavLink
            key={nav.route}
            to={nav.route}
            className={({ isActive }) =>
              clsx(styles.subnavItem, {
                [styles.active]: isActive
              })
            }
            end
          >
            <div className={styles.subnavItemLabel}>{nav.label}</div>
          </NavLink>
        ))}
      </div>

      <Outlet />
    </div>
  );
}
