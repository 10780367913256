import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import SvgIcon from "components/General/SvgIcon";
import SocketClient from "support/SocketClient";
import LoadingOrbs from "components/General/LoadingOrbs";
import Image from "components/General/Image";

import { Video } from "support/types/videos";
import useBackend from "hooks/useBackend";

import styles from "./style.module.scss";
import toast from "support/toast";

type VideosGridProps = {
  title?: string;
  className?: string;
};

export default function VideosGrid(props: VideosGridProps) {
  const navigate = useNavigate();
  const [videos, setVideos] = useState<Video[]>([]);
  const [isVideosLoading, setIsVideosLoading] = useState(true);

  const { get } = useBackend();

  const { user } = useAuth();

  useEffect(() => {
    const socketClient = SocketClient.getInstance();
    const socket = SocketClient.getSocket();

    const controller = new AbortController();
    const signal = controller.signal;

    const handleVideoCreated = ({ video }: { video: Video }) => {
      setVideos(prevVideos => [video, ...prevVideos]);
    };

    const handleVideoUpdated = ({ video }: { video: Video }) => {
      setVideos(prevVideos => {
        const index = prevVideos.findIndex(v => v.id === video.id);

        return [
          ...prevVideos.slice(0, index),
          video,
          ...prevVideos.slice(index + 1)
        ];
      });
    };

    const handleRenderFailed = ({
      video,
      failedReason
    }: {
      video: Video;
      failedReason: string;
    }) => {
      handleVideoUpdated({ video });

      toast.error(failedReason);
    };

    const userVideosChannel = `${user?.id}-videos`;

    socketClient.subscribeToChannel(userVideosChannel);

    socket.on("videoCreated", handleVideoCreated);

    socket.on("renderStarted", handleVideoUpdated);

    socket.on("renderComplete", handleVideoUpdated);

    socket.on("renderFailed", handleRenderFailed);

    get("/videos", { signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setVideos(jsonResonponse.data.videos);
          } catch (error) {}
        }
        setIsVideosLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsVideosLoading(false);
        }
      });

    return () => {
      socket.off("videoCreated", handleVideoCreated);
      socket.off("renderStarted", handleVideoUpdated);
      socket.off("renderComplete", handleVideoUpdated);
      socket.off("renderFailed", handleRenderFailed);

      socketClient.unsubscribeFromChannel(userVideosChannel);

      controller.abort();
    };
  }, [get, user]);

  const handleOpenVideo = (id: string) => {
    navigate(`/videos/${id}`);
  };

  const getThumbnail = (video: Video) => {
    switch (video.status) {
      case "queueing":
        return (
          <LoadingOrbs
            text="Queueing"
            variant="3"
            className={styles.videoCoverPhoto}
          />
        );
      case "generating":
        return (
          <div className={styles.videoCoverPhotoWrap}>
            <LoadingOrbs
              text="Generating"
              variant="2"
              className={styles.videoCoverPhoto}
            />
          </div>
        );
      case "rendering":
        return (
          <LoadingOrbs text="Rendering" className={styles.videoCoverPhoto} />
        );
    }

    if (!!video.posterThumbUrl) {
      return (
        <Image
          src={video.posterThumbUrl}
          className={styles.videoCoverPhoto}
          alt="Video Thumbnail"
        />
      );
    }

    return (
      <div className={styles.videoNoThumb}>
        <SvgIcon name="video-file" className={styles.noThumbIcon} />
      </div>
    );
  };

  const getIsVideoBusy = (video: Video) => {
    return video.status === "queueing" || video.status === "rendering";
  };

  return (
    <div className={props.className}>
      <div className={styles.videosHeader}>
        <h2 className={styles.videosTitle}>{props.title || "Videos"}</h2>

        <button
          className={clsx("btn btn-primary rounded-pill", styles.createButton)}
          disabled
        >
          + Create New
        </button>
      </div>

      {!isVideosLoading && videos.length < 1 && (
        <div className={styles.noVideosBox}>
          <h4>You do not have any videos yet</h4>

          <button
            className={clsx(
              "btn btn-primary rounded-pill",
              styles.createButton
            )}
            disabled
          >
            + Start Creating
          </button>
        </div>
      )}

      <div className={styles.videoGrid}>
        {isVideosLoading
          ? Array.from({ length: 8 }, (_, index) => (
              <div
                key={index + 1}
                className={clsx(styles.videoItem, styles.busy)}
              >
                <div className={styles.videoCoverPhotoWrap}>
                  <div className={styles.videoLoading}>
                    <SvgIcon name="video-file" className={styles.loadingIcon} />
                  </div>
                </div>
              </div>
            ))
          : videos.map(video => (
              <div
                key={video.id}
                className={clsx(styles.videoItem, {
                  [styles.busy]: getIsVideoBusy(video)
                })}
                onClick={() => handleOpenVideo(video.id)}
              >
                <div className={styles.videoCoverPhotoWrap}>
                  {getThumbnail(video)}
                </div>
                <div className={styles.videoFooter}>
                  <div className={styles.videoFooterContent}>
                    <div className={styles.videoIconWrap}>
                      <SvgIcon name="video-play" />
                    </div>
                    <div className={styles.videoTitle}>{video.title}</div>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
