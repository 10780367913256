import clsx from "clsx";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "bootstrap";

import SvgIcon from "components/General/SvgIcon";
import VideoPlayer from "components/General/VideoPlayer";
import ScriptEditor from "components/ScriptEditor";

import { Video, VideoImage } from "support/types/videos";
import SocketClient from "support/SocketClient";
import toast from "support/toast";

import { useAuth } from "contexts/AuthContext";
import useBackend from "hooks/useBackend";

import SelectVideoSizeDropdown from "components/General/SelectVideoSizeDropdown";
import LoadingOrbs from "components/General/LoadingOrbs";
import Scrollbar from "components/General/Scrollbar";
import Image from "components/General/Image";
import Spinner from "components/General/Spinner";

import styles from "./style.module.scss";

const titleMaxLength = 100;

export default function EditVideo() {
  const parentRef = useRef<HTMLDivElement>(null);
  let { id: videoId } = useParams();
  const [video, setVideo] = useState<Video>();
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [size, setSize] = useState("1080x1920");

  const [isImagesLoading, setIsImagesLoading] = useState(true);
  const [images, setImages] = useState<VideoImage[]>([]);

  const [isSendingForRerender, setIsSendingForRerender] = useState(false);

  const { get, put } = useBackend();

  const { user } = useAuth();

  useEffect(() => {
    let tooltips: InstanceType<typeof Tooltip>[] = [];

    if (parentRef.current) {
      const tooltipTriggerList = parentRef.current.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );

      tooltips = [...tooltipTriggerList].map(
        tooltipTriggerEl => new Tooltip(tooltipTriggerEl)
      );
    }

    if (video) {
      setTitle(video.title);
    }

    // Always cleanup side effects
    return () => {
      tooltips.forEach(tooltip => tooltip.dispose());
    };
  }, [video]);

  useEffect(() => {
    const controller = new AbortController();

    get(`/videos/${videoId}`, { signal: controller.signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setVideo(jsonResonponse.data.video);
          } catch (error) {}
        }
        setIsVideoLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsVideoLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [get, videoId]);

  useEffect(() => {
    const controller = new AbortController();

    if (!video) {
      return;
    }

    if (!video.scriptBusy) {
      get(`/videos/${video.id}/images`, { signal: controller.signal })
        .then(async res => {
          if (res.ok) {
            try {
              const jsonResonponse = await res.json();

              setImages(jsonResonponse.data.images);
            } catch (error) {}
          }
          setIsImagesLoading(false);
        })
        .catch(e => {
          if (e.name !== "AbortError") {
            setIsImagesLoading(false);
          }
        });
    }

    return () => {
      controller.abort();
    };
  }, [get, video]);

  useEffect(() => {
    const socketClient = SocketClient.getInstance();
    const socket = SocketClient.getSocket();

    const handleVideoUpdated = ({ video }: { video: Video }) => {
      setVideo(video);
    };

    const handleImageUpdated = ({ image }: { image: VideoImage }) => {
      const index = images.findIndex(i => i.id === image.id);
      if (index !== -1) {
        setImages(prevImages => [
          ...prevImages.slice(0, index),
          image,
          ...prevImages.slice(index + 1)
        ]);
      }
    };

    const handleRenderFailed = ({
      video,
      failedReason
    }: {
      video: Video;
      failedReason: string;
    }) => {
      handleVideoUpdated({ video });

      toast.error(failedReason);
    };

    if (!video) {
      return;
    }

    const videoChannel = `video-${video.id}`;
    socketClient.subscribeToChannel(videoChannel);

    socket.io.on("reconnect", () => {
      console.log("Socket reconnected");
      socketClient.subscribeToChannel(videoChannel);
    });

    socket.on("renderStarted", handleVideoUpdated);

    socket.on("videoUpdated", handleVideoUpdated);

    socket.on("imageUpdated", handleImageUpdated);

    socket.on("renderComplete", handleVideoUpdated);

    socket.on("renderFailed", handleRenderFailed);

    return () => {
      socket.off("renderStarted", handleVideoUpdated);
      socket.off("videoUpdated", handleVideoUpdated);
      socket.off("imageUpdated", handleImageUpdated);
      socket.off("renderComplete", handleVideoUpdated);
      socket.off("renderFailed", handleRenderFailed);

      socketClient.unsubscribeFromChannel(videoChannel);
    };
  }, [user, video, images]);

  const handleVideoSizeChange = (size: string) => {
    size && setSize(size);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleRerender = async () => {
    if (!video) {
      return;
    }

    setIsSendingForRerender(true);

    try {
      const res = await put(`/videos/magicgen/${video.id}`);

      const jsonResonponse = await res.json();

      if (!res.ok) {
        toast.error(jsonResonponse.message);

        toast.success(
          "Your video has been successfully added to the render queue."
        );
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }

    setIsSendingForRerender(false);
  };

  const generateLodingTexts = (
    n: number
  ): Array<{ number: number; width: number }> => {
    const getRandomWidth = (min: number, max: number): number => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const array = [];
    for (let i = 1; i <= n; i++) {
      array.push({ number: i, width: getRandomWidth(90, 100) });
    }
    return array;
  };

  const generateImagesLoading = (n: number) => {
    return Array.from({ length: n }, (_, index) => (
      <div key={index + 1} className={styles.imageItem}>
        <div className={styles.imageLoadingItem}></div>
      </div>
    ));
  };

  const getThumbnail = (video: Video) => {
    switch (video.status) {
      case "queueing":
        return (
          <div className={styles.videoCoverPhotoWrap}>
            <LoadingOrbs
              text="Queueing"
              variant="3"
              className={styles.videoCoverPhoto}
            />
          </div>
        );
      case "generating":
        return (
          <div className={styles.videoCoverPhotoWrap}>
            <LoadingOrbs
              text="Generating"
              variant="2"
              className={styles.videoCoverPhoto}
            />
          </div>
        );
      case "rendering":
        return (
          <div className={styles.videoCoverPhotoWrap}>
            <LoadingOrbs text="Rendering" className={styles.videoCoverPhoto} />
          </div>
        );
    }

    if (!!video.videoUrl) {
      return <VideoPlayer src={video.videoUrl} poster={video.posterThumbUrl} />;
    }

    return (
      <div className={styles.videoCoverPhotoWrap}>
        <div className={styles.videoNoThumb}>
          <SvgIcon name="video-file" className={styles.noThumbIcon} />
        </div>
      </div>
    );
  };

  const getImageThumbnail = (image: VideoImage) => {
    switch (image.status) {
      case "ready":
        return <Image src={image.thumbUrl} alt={image.prompt} />;
      case "generating":
        return (
          <div className={styles.imageLoadingItem}>
            <LoadingOrbs
              text=""
              variant="4"
              className={styles.imageGenerating}
            />
          </div>
        );
      case "pending":
        return <div className={styles.imageLoadingItem}></div>;
      default:
        return (
          <div className={styles.imageLoadingItem}>
            <SvgIcon name="question-mark" />
          </div>
        );
    }
  };

  const isPageBusy = isVideoLoading || !video;
  const canRerender = video && video.status === "failed";

  return (
    <>
      <div className={styles.editorNav}>
        <div className={clsx("container", styles.navContainer)}>
          {isPageBusy ? (
            <div className={styles.navLoading}>
              <div>
                <div
                  className={styles.buttonLoading}
                  style={{ borderRadius: `4px`, width: "80px" }}
                ></div>
                <div
                  className={styles.buttonLoading}
                  style={{ borderRadius: `4px`, width: "150px" }}
                ></div>
              </div>
              <div>
                <div className={styles.buttonLoading}></div>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.sizeSelectWrap}>
                <div className={styles.sizeSelectLabel}>Video size:</div>{" "}
                <SelectVideoSizeDropdown
                  value={size}
                  onChange={handleVideoSizeChange}
                />
              </div>

              <div className={styles.rightButtons}>
                <button
                  className={clsx(
                    "btn btn-primary rounded-pill text-white px-4"
                  )}
                  disabled={!canRerender || isSendingForRerender}
                  onClick={handleRerender}
                >
                  {isSendingForRerender ? <Spinner /> : "Render Video"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div ref={parentRef} className={styles.editMainContent}>
        <div className={styles.row}>
          <div className={styles.editorCol}>
            <div>
              <label
                htmlFor="vidTitle"
                className={clsx("form-label", styles.formLabel)}
              >
                Title
                <SvgIcon
                  name="help-circle-fill"
                  className={styles.helpIcon}
                  data-bs-toggle="tooltip"
                  data-bs-title="Your title will appear in your video's thumbnail."
                  data-bs-placement="right"
                  data-bs-custom-class={styles.labelTip}
                  data-bs-trigger="hover"
                />
              </label>
              {isPageBusy ? (
                <div className={styles.titleLoading}>
                  <div
                    className={styles.editorLoadingText}
                    style={{ width: "70%" }}
                  ></div>
                </div>
              ) : (
                <div className={styles.inputWrap}>
                  <input
                    id="vidTitle"
                    className={clsx("form-control", styles.titleInput)}
                    value={title}
                    maxLength={titleMaxLength}
                    autoComplete="off"
                    onChange={handleTitleChange}
                  />
                  <div className={styles.inputLimits}>
                    {title.length} / {titleMaxLength}
                  </div>
                </div>
              )}
            </div>
            <div>
              <label
                htmlFor="vidScript"
                className={clsx("form-label", styles.formLabel)}
              >
                Script
                <SvgIcon
                  name="help-circle-fill"
                  className={styles.helpIcon}
                  data-bs-toggle="tooltip"
                  data-bs-title="Your script will be narrated by the AI voiceover exactly as written. It also serves as the transcript for subtitles."
                  data-bs-placement="right"
                  data-bs-custom-class={styles.labelTip}
                  data-bs-trigger="hover"
                />
              </label>
              <div className={styles.editorWrap}>
                {isPageBusy || video.scriptBusy ? (
                  <div className={styles.editorLoading}>
                    <div className={styles.editorLoadingParagraph}>
                      {generateLodingTexts(5).map(n => (
                        <div
                          key={n.number}
                          className={styles.editorLoadingText}
                          style={{ width: `${n.width}%` }}
                        ></div>
                      ))}
                    </div>
                    <div className={styles.editorLoadingParagraph}>
                      {generateLodingTexts(5).map(n => (
                        <div
                          key={n.number}
                          className={styles.editorLoadingText}
                          style={{ width: `${n.width}%` }}
                        ></div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <ScriptEditor content={video.script} />
                )}
              </div>
              {!isPageBusy && (
                <div className={styles.inputTip}>
                  <SvgIcon name="info-circle" className={styles.tipIcon} />{" "}
                  Always verify AI generated infomation for accuracy.
                </div>
              )}
            </div>
          </div>
          <div className={styles.videoCol}>
            {isPageBusy ? (
              <div className={styles.videoCoverPhotoWrap}>
                <div className={styles.videoLoading}>
                  <SvgIcon name="video-file" className={styles.loadingIcon} />
                </div>
              </div>
            ) : (
              getThumbnail(video)
            )}
          </div>
        </div>

        <label
          htmlFor="vidImages"
          className={clsx("form-label", styles.formLabel)}
        >
          Visuals
          <SvgIcon
            name="help-circle-fill"
            className={styles.helpIcon}
            data-bs-toggle="tooltip"
            data-bs-title="The number of visual elements will determine how long each element will be visible."
            data-bs-placement="right"
            data-bs-custom-class={styles.labelTip}
            data-bs-trigger="hover"
          />
        </label>
        <div className={styles.imagesContainer}>
          <Scrollbar className={styles.imagesScroll}>
            <div className={styles.imagesWidth}>
              {!isImagesLoading
                ? images.map(image => (
                    <div key={image.id} className={styles.imageItem}>
                      {getImageThumbnail(image)}
                    </div>
                  ))
                : generateImagesLoading(12)}
            </div>
          </Scrollbar>
        </div>
      </div>
    </>
  );
}
