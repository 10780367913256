import clsx from "clsx";
import React, { useRef, useEffect, useState, MouseEvent } from "react";
import WaveSurfer from "wavesurfer.js";
import SvgIcon from "../SvgIcon";

import styles from "./style.module.scss";

interface AudioPlayerProps {
  src: string;
  variant?: string;
  className?: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  src,
  variant,
  className
}) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (waveformRef.current) {
      let colors = ["#b7b9ba", "#2272ff"];
      if (variant === "white") {
        colors = ["white", "#37ec67"];
      }

      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: colors[0],
        progressColor: colors[1],
        barWidth: 3,
        barRadius: 2,
        barGap: 2,
        height: 20,
        cursorWidth: 0,
        interact: false,
        url: src
      });

      waveSurferRef.current.on("interaction", () => {
        waveSurferRef.current?.play();
        setIsPlaying(true);
      });

      waveSurferRef.current.on("finish", () => {
        waveSurferRef.current?.seekTo(0);
        setIsPlaying(false);
      });

      waveSurferRef.current.on("pause", () => {
        setIsPlaying(false);
      });

      waveSurferRef.current.on("play", () => {
        setIsPlaying(true);
      });

      waveSurferRef.current.on("error", (e: any) => {
        console.error("WaveSurfer error:", e);
      });
    }

    return () => {
      waveSurferRef.current?.destroy();
    };
  }, [src, variant]);

  const handlePlayPause = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (waveSurferRef.current) {
      if (waveSurferRef.current.isPlaying()) {
        waveSurferRef.current.pause();
      } else {
        waveSurferRef.current.play();
      }
    }
  };

  return (
    <div className={clsx(className, styles.voiceSamplePlayer)}>
      <button
        onClick={handlePlayPause}
        className={clsx(styles.playButton, { [styles.isPlaying]: isPlaying })}
      >
        <SvgIcon
          name={isPlaying ? "play-stop" : "play"}
          className={styles.playIcon}
        />
        Sample
      </button>
      <div ref={waveformRef} className={styles.waveform} />
    </div>
  );
};

export default AudioPlayer;
