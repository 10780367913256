import { useState } from "react";

import SvgIcon from "components/General/SvgIcon";
import { Modal } from "components/General/Modal";
import Spinner from "components/General/Spinner";

import { useAuth } from "contexts/AuthContext";
import { useBilling } from "contexts/BillingContext";

import { getPriceTotal } from "support/helpers";
import toast from "support/toast";
import { AuthUser } from "support/types";

import { useAppDispatch } from "hooks";
import useBackend from "hooks/useBackend";

import { setUser } from "store/reducers/authSlice";

import "./style.scss";

interface ManageSubModalProps {
  id?: string;
  show: boolean;
  onClose: () => void;
}

export function ManageSubModal({ id, show, onClose }: ManageSubModalProps) {
  const { user } = useAuth();
  const { showUpgradeModal, userPlan, userFeatures } = useBilling();
  const dispatch = useAppDispatch();

  const { post } = useBackend();

  const [action, setAction] = useState<string | null>(null);
  const [isShowCancelScreen, setIsShowCancelScreen] = useState(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isPayMethodLoading, setIsPayMethodLoading] = useState<boolean>(false);

  const handleContinue = () => {
    switch (action) {
      case "cancel":
        setIsShowCancelScreen(true);
        break;

      case "update-payment":
        handleChangeBillingInfo();
        break;

      case "update-billing":
        handleChangeBillingInfo();
        break;

      default:
        break;
    }
  };

  const handleGoBack = () => {
    setAction(null);
    setIsShowCancelScreen(false);
  };

  const handleOnClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }

    setAction(null);
    setIsShowCancelScreen(false);
  };

  const handleChangePlan = () => {
    handleOnClose();

    showUpgradeModal();
  };

  const initStripePortal = async () => {
    setIsPayMethodLoading(true);

    try {
      const res = await post("/billing/create-portal-session");

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setIsPayMethodLoading(false);
      } else {
        window.location.href = jsonResonponse.data.url;

        // Intentionally refuse to stop spinner
      }
    } catch (error) {
      setIsPayMethodLoading(false);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const handleChangeBillingInfo = async () => {
    setIsPayMethodLoading(true);

    initStripePortal();
  };

  const handleCancelSubscription = async () => {
    setIsCancelling(true);

    try {
      const res = await post("/billing/subscription/cancel");

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setIsCancelling(false);
      } else if (jsonResonponse.status === 200) {
        // Note: This endpoint when successful emits an subscriptionUpdated event
        // which is then handled by the BillingContext Provider.
        dispatch(
          setUser({
            ...user,
            subscription: {
              ...user?.subscription
            }
          } as AuthUser)
        );

        toast.success(
          `We've canceled your subscription. You may continue to use Soutle until the end of your current billing period.`
        );

        setIsCancelling(false);
        handleOnClose();
      }
    } catch (error) {
      setIsCancelling(false);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const userPlanPrice = userPlan?.prices.find(
    p => user?.subscription && p.slug === user?.subscription.priceSlug
  );

  return (
    <Modal
      id={id}
      show={show}
      className="manage-sub-modal"
      onClose={handleOnClose}
    >
      {isShowCancelScreen ? (
        <>
          <button
            type="button"
            className="btn-close back"
            onClick={handleGoBack}
          >
            <SvgIcon name="arrow-left" />
          </button>

          <h5 className="modal-title mb-4">
            Before you go, consider what you'll miss
          </h5>

          <ul className="features-to-drop-list">
            {userFeatures
              .filter(ft => ft.enabled)
              .map(feature => (
                <li key={feature.id}>{feature.label}</li>
              ))}
          </ul>

          <div className="text-end mt-4">
            <button
              className="btn btn-danger rounded-pill w-100 text-white py-2 px-4 mb-2 fw-bold"
              disabled={isCancelling}
              onClick={handleCancelSubscription}
            >
              {!isCancelling ? "Continue to cancel" : <Spinner />}
            </button>
            <button
              className="btn btn-outline border border-dark rounded-pill w-100 py-2 px-4 fw-bold"
              onClick={handleChangePlan}
            >
              Change to another plan
            </button>
          </div>
        </>
      ) : (
        <>
          <h5 className="modal-title">Manage Your Subscription</h5>
          {userPlan && userPlanPrice && (
            <div className="modal-description">
              {userPlan.title} at $
              {getPriceTotal(
                userPlanPrice.amountMonthly,
                userPlanPrice.interval
              )}{" "}
              / {userPlanPrice?.interval === "monthly" ? "month" : "year"}
            </div>
          )}
          <button type="button" className="btn-close" onClick={handleOnClose}>
            <SvgIcon name="close-circle" />
          </button>
          <div className="manage-sub-options">
            <div className="sub-option-item">
              <label
                htmlFor="subUpdatePaymentMethod"
                className="sub-option-label"
              >
                Update payment method
              </label>
              <div className="form-check form-control-lg">
                <input
                  className="form-check-input"
                  type="radio"
                  name="action"
                  value={"update-payment"}
                  id="subUpdatePaymentMethod"
                  checked={action === "update-payment"}
                  onChange={() => setAction("update-payment")}
                />
              </div>
            </div>
            <div className="sub-option-item">
              <label htmlFor="subUpdateInfo" className="sub-option-label">
                Update billing information
              </label>
              <div className="form-check form-control-lg">
                <input
                  className="form-check-input"
                  type="radio"
                  name="action"
                  value={"update-billing"}
                  checked={action === "update-billing"}
                  id="subUpdateInfo"
                  onChange={() => setAction("update-billing")}
                />
              </div>
            </div>
            <div className="sub-option-item">
              <label htmlFor="subcancel" className="sub-option-label">
                Cancel subscription
              </label>
              <div className="form-check form-control-lg">
                <input
                  className="form-check-input"
                  type="radio"
                  name="action"
                  value={"cancel"}
                  checked={action === "cancel"}
                  id="subcancel"
                  onChange={() => setAction("cancel")}
                />
              </div>
            </div>
          </div>
          <div className="text-end mt-4">
            <button
              className="btn btn-primary rounded-pill w-100 text-white py-2 px-4 fw-bold"
              disabled={!action || isPayMethodLoading}
              onClick={handleContinue}
            >
              {!isPayMethodLoading ? "Next" : <Spinner />}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
}
