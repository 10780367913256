import clsx from "clsx";
import SubmitButton, {
  SubmitButtonProps
} from "components/General/SubmitButton";

import styles from "./style.module.scss";

export default function AutnsubmitButton(props: SubmitButtonProps) {
  return (
    <SubmitButton {...props} className={clsx("btn", styles.submitButton)}>
      {props.children}
    </SubmitButton>
  );
}
