import { Link } from "react-router-dom";

export default function Series() {
  return (
    <div>
      <h1>Series</h1>

      <div className="shadow p-5 text-center rounded mt-4">
        <h1 className="mt-5 pt-5 fw-bold">Coming Soon!</h1>
        <p className="mb-5 pb-5 text-muted fw-normal">
          Create video series that will be generated and automatically posted on
          your social media <Link to="/channels">Channels</Link>.
        </p>
      </div>
    </div>
  );
}
