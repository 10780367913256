import clsx from "clsx";
import { Link } from "react-router-dom";

import MinimalUserDropdown from "../MinimalUserDropdown";

import { useAuth } from "contexts/AuthContext";

import styles from "./style.module.scss";

export default function MinimalNavbar() {
  const { isAuthenticated } = useAuth();

  return (
    <nav className={styles.minimalNavbar}>
      <div className={clsx("container", styles.container)}>
        <Link to="/" className={styles.logo}>
          <div className="invisible">Soutle</div>
          <div className={styles.by}> by Aptiw</div>
        </Link>

        {isAuthenticated && (
          <div className={styles.right}>
            <MinimalUserDropdown />
          </div>
        )}
      </div>
    </nav>
  );
}
