import clsx from "clsx";

import styles from "./style.module.scss";

import SvgIcon from "components/General/SvgIcon";

export type InputFieldProps = {
  children?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  id?: string;
  name?: string;
  icon?: string;
  placeholder?: string;
  type?: string;
  tip?: string;
  errors?: string[] | undefined;
};

export default function Inputfield({
  type = "text",
  ...props
}: InputFieldProps) {
  return (
    <div className={clsx(styles.inputField, props.className)}>
      <div className={styles.inputWrap}>
        {props.icon && (
          <SvgIcon name={props.icon} className={styles.inputIcon} />
        )}
        <input
          id={props.id}
          name={props.name}
          type={type}
          className={clsx("form-control", styles.input, props.inputClassName, {
            [styles.hasIcon]: !!props.icon,
            "is-invalid": !!props.errors
          })}
          placeholder={props.placeholder}
        />
        {props.children}
      </div>
      {props.tip && <div className="form-text">{props.tip}</div>}
      {props.errors
        ? props.errors.map((message, i) => (
            <div
              key={i + 1}
              className={clsx("invalid-feedback", styles.showInvalidFeedback)}
            >
              {message}
            </div>
          ))
        : null}
    </div>
  );
}
